import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from "react-i18next";

function PendingPaymentsAlert({count}) {

    const [msg, setMsg] = useState('');
    const {t} = useTranslation()

    useEffect(() => {

        setMsg(count === 1 ?
            t('PENDING_PAYMENT') :
            t('PENDING_PAYMENTS')
        )

    }, [count])

    return (
        <React.Fragment>
            {
                count > 0 ?
                <Link
                    target="_blank" to={`/console/payments`} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                    <div role='alert' className={'alert alert-warning'}>
                        {msg}
                    </div>
                </Link> : null
            }
        </React.Fragment>
    )
}

export default PendingPaymentsAlert;
