import React, {useEffect, useRef, useState} from 'react';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StripeDocumentsCheckout from './StripeDocumentsCheckout';

const stripeObject = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

function StripeDocumentsModal({isOpen, months, discount, buyState, onCancel, onStripeApproved, org, type}) {

    const modalRef = useRef(null);
    const [working, setWorking] = useState(false);
    const [succeeded, setSucceeded] = useState(false);

    useEffect(() => {
        if(!modalRef.current) {
            return;
        }
        if(isOpen) {
            modalRef.current.showModal();
        } else {
            modalRef.current.close();
        }
    }, [isOpen])

    function handleDismiss(){
        if(succeeded) {
            onStripeApproved();
        } else {
            onCancel();
        }
    }

    function onSucceeded() {
        setSucceeded(true);
    }

    function onWorking() {
        setWorking(true);
    }

    function onProccesEnd() {
        setWorking(false);
    }

    return (
            <dialog ref={modalRef} className={'modal'}>
                <div className={'prose modal-box'}>
                    {
                        isOpen &&
                        <>
                            <h3>
                                Pago de documentos
                            </h3>
                            <Elements stripe={stripeObject}>
                                <StripeDocumentsCheckout buyState={buyState} months={months} discount={discount} onSucceeded={onSucceeded} onWorking={onWorking} onProccesEnd={onProccesEnd} org={org} type={type} />
                            </Elements>
                            <div className={'modal-action'}>
                                {/* <Button type="submit" disabled={!working} color="primary">Pagar</Button> */}
                                <button className={'btn'} disabled={working} color="secondary" onClick={handleDismiss}>
                                    {
                                        succeeded ? <React.Fragment>
                                            Cerrar
                                        </React.Fragment> : <React.Fragment>
                                            Cancelar
                                        </React.Fragment>
                                    }
                                </button>
                            </div>
                        </>
                    }
                </div>
            </dialog>
    );
}
export default StripeDocumentsModal;
