import React, {useReducer, useEffect, useState} from 'react';
import {Route, Link, LinkContainer, Switch, useLocation} from 'react-router-dom'
import InvoiceDataList from './invoiceData/InvoiceDataList';
import SupportDataList from './supportData/SupportDataList';
import Organizations from './organizations/Organizations';
import Organization from './organizations/Organization';
import NewOrganization from './organizations/NewOrganization';
import Payments from './payments/Payments';
import {Navbar, NavbarBrand, Nav, NavItem} from 'reactstrap';
import PendingPaymentsAlert from './shared/PendingPaymentsAlert';
import {consoleReducer, types, dispatchMiddleware, ConsoleDispatch} from './reducers/reducers';
import IconButton from './shared/IconButton';
import EmailRegistration from './info/EmailRegistration';
import UserAdded from './info/UserAdded';

import { useTranslation } from 'react-i18next';

import {httpGet} from './shared/Config';
import AlternateStorageSubscription from './organizations/AlternateStorageSubscription';
import {Icon} from "@iconify/react";

function FirmamexConsole({match}) {

    const {t} = useTranslation();
    const [consolec, dispatch] = useReducer(consoleReducer, {})
    const dispatcher = dispatchMiddleware(dispatch);
    const [serverConf, setServerConf] = useState({});
    const [checkDone, setCheckDone] = useState(false);
    const [clientType, setClientType] = useState('DEMO');
    const {pathname} = useLocation();


    useEffect(() => {
        dispatcher({type: types.UPDATE_PAYMENT_INFO});
        fetchAccountStatus();
    }, [])


    function handleSignOutClick() {
        localStorage.removeItem('jwt');
        window.location.href = 'https://firmamex.com/pdf/default/';
    }

    function handleAppClick() {
        window.open('https://firmamex.com/pdf/default/', '_blank');
    }

    async function fetchAccountStatus() {
        const response = await httpGet(`/orgs/accountStanding/clientType`);

        if(response.data) {
            if(response.data.clientType) {
                let cType = response.data.clientType;
                setClientType(cType);
                fetchServerConf();
            }
        }
    }

    async function fetchServerConf() {
        try {
            const response = await httpGet('/getServerConf');
            if(response?.data.jsonData) {
                let parseConf = JSON.parse(response.data.jsonData);
                setServerConf(parseConf);
                setCheckDone(true);
            }
        } catch(e) {
            console.error(e);
        }
    }

    return (
        <ConsoleDispatch.Provider value={dispatcher}>
            <div className="drawer lg:drawer-open">
                <input id="my-drawer-2" type="checkbox" className="drawer-toggle"/>
                <div className="drawer-content flex flex-col">
                    <div className="navbar bg-white border-base-200  w-full ">
                        <div className="flex-none lg:hidden">
                            <label htmlFor="my-drawer-2" aria-label="open sidebar" className="btn btn-square btn-ghost">
                                <Icon icon="material-symbols:menu" width="24" height="24" />
                            </label>
                        </div>
                    </div>
                    <div className="container p-4">
                        <PendingPaymentsAlert count={consolec.pendingPayments}/>
                        <br/>
                        <Route path={`${match.path}/invoiceData`} component={InvoiceDataList}/>
                        <Route path={`${match.path}/supportData`} component={SupportDataList}/>
                        <Route path={`${match.path}/payments`} component={Payments}/>
                        <Switch>
                            <Route exact path={`${match.path}/orgs/new`} component={NewOrganization}/>
                            <Route exact path={`${match.path}/orgs`} component={Organizations}/>
                            <Route exact path={`${match.path}/subscriptions/:id`} component={AlternateStorageSubscription}/>
                            <Route exact path={`${match.path}/emailRegistered`} component={EmailRegistration}/>
                            <Route exact path={`${match.path}/userAdd`} component={UserAdded}/>
                            <Route path={`${match.path}/orgs/:id`} component={Organization}/>
                        </Switch>
                    </div>
                </div>
                <div className="drawer-side">
                    <label htmlFor="my-drawer-2" aria-label="close sidebar" className="drawer-overlay"></label>
                    <ul className="menu text-base-content min-h-full w-60 p-4  bg-gray-50">
                        <div className="font-title text-lg font-bold m-3">Firmamex</div>
                        <li>
                            <Link to={"/console/orgs"} className={pathname.startsWith('/console/orgs') ? 'active' : ''}>
                                <Icon icon={'ic:outline-home'}></Icon>
                                <span>{t('MY_ACCOUNT')}</span>
                            </Link>
                        </li>
                        {
                            checkDone && clientType !== "DEMO" && serverConf?.userMode !== "chihuahua" ?
                                <>
                                    <li><Link to={"/console/payments"}
                                    className={pathname === '/console/payments' ? 'active' : ''}>
                                        <Icon icon={'ic:outline-account-balance-wallet'}></Icon>
                                        <span>{t('PAYMENT_INFO')}</span></Link></li>
                                    <li><Link to={"/console/invoiceData"}
                                    className={pathname === '/console/invoiceData' ? 'active' : ''}>
                                        <Icon icon={'ic:outline-contact-mail'}></Icon>
                                        <span>{t('BILLING_SUPPORT')}</span>
                                        </Link></li>
                                </> : null
                        }
                        <li>
                            <a onClick={handleSignOutClick}>
                                    <Icon icon={'ic:baseline-logout'}></Icon>
                                    <span>{t('LOG_OUT')}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

        </ConsoleDispatch.Provider>
    )
}

export default FirmamexConsole;
