import React from 'react';
import { useTranslation } from 'react-i18next';

function UserAdded() {
    const {t} = useTranslation();   
    return(
        <div className="container">
            <p>{t('USER_REGISTRATION_COMPLETED')}</p>
        </div>
    )
}

export default UserAdded