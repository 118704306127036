import React from 'react';

export default function InputText({value, onChange, children, type = 'text', ...rest}) {
    return (
        <label className="form-control w-full ">
            <div className="label">
                <span className="label-text">
                    {children}
                </span>
            </div>
            <input type={type}
                   className="input input-bordered w-full "
                   value={value}
                   onChange={onChange}
                   {...rest}
            />
        </label>
    )
}
