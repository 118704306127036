import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import React, {useState} from "react";
import {httpPost} from "../shared/Config";
import MoneyFormat from "../shared/MoneyFormat";
import SelectPaymentType from "../payments/SelectPaymentType";
import ConfirmationModal from "../shared/ConfirmationModal";
import EmailNoticeSPEI from "../payments/EmailNoticeSPEI";


import { useTranslation } from 'react-i18next';
import SimpleModal from "../shared/SimpleModal";
import InputText from "../shared/InputText";

const debounce = (mainFunction, delay) => {
    // Declare a variable called 'timer' to store the timer ID
    let timer;

    // Return an anonymous function that takes in any number of arguments
    return function (...args) {
        // Clear the previous timer to prevent the execution of 'mainFunction'
        clearTimeout(timer);

        // Set a new timer that will execute 'mainFunction' after the specified delay
        timer = setTimeout(() => {
            mainFunction(...args);
        }, delay);
    };
};

const calcTotal = debounce(async function (value, callback) {
    const res = await httpPost('/calculateTotalVerifications', value)
    callback(res.data)
}, 1000)

function BuyGlobalIdentityVerificationsModal({isOpen, onCancel, tenant, onSaved}) {

    const {t} = useTranslation();
    const [paymentType, setPaymentType] = useState('SPEI');
    const [amountSelected, setAmountSelected] = useState(0);
    const [prices, setPrices] = useState({});
    const [working, setWorking] = useState(false);
    const [showSpeiModal, setShowSpeiModal] = useState(false);
    const [noticeModal, setNoticeModal] = useState(false);
    const [error, setError] = useState(null);

    async function handlePaySubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        if (paymentType === 'Stripe0') {
            const response = await httpPost(`/payments/verifications/intentStripePayment`, {
                verifications: amountSelected,
                paymentType
            })
            if (response.data.error) {
                setError(response.data.error);
            } else if (response.data.checkoutUrl) {
                window.location.href = response.data.checkoutUrl;
            }
        } else {
            setShowSpeiModal(true);
        }
    }

    async function handleSpeiSubmit() {
        setWorking(true);
        setShowSpeiModal(false);
        try {
            const res = await httpPost(`/orgs/globalVerifications`, {
                verifications: amountSelected,
                paymentType
            })
            const {success, error} = res.data;
            if(success) {
                setNoticeModal(true);
            } else {
                console.log(error);
            }
        } catch(e) {

        }
        setWorking(false)
    }

    function handleSpeiCancel() {
        setShowSpeiModal(false);
    }

    function handleCancel() {
        onCancel();
    }

    function handleVerificationsAmountChange(paymentType, amount) {
        setWorking(true)
        calcTotal({
            paymentType,
            verifications: amount
        }, r => {
            if (r.success) {
                setPrices(r.data);
            }
            setWorking(false)
        });
    }

    function handlePaymentTypeChange(type) {
        setPaymentType(type);
        handleVerificationsAmountChange(type, amountSelected);
    }

    return (
        <SimpleModal isOpen={isOpen}>
            <form onSubmit={handlePaySubmit}>
                <h3>
                    {t('BUY_VERIFICATIONS')}
                </h3>
                <div>
                    <div>
                        <div style={{marginBottom: '2%'}}>
                            {t('ALL_PRICES_INCLUDE_TAX_AND_EXPIRE', {
                                months: '15',
                                buyType: t('verificaciones')
                            })}
                        </div>
                    </div>
                    <InputText
                        type="number"
                        value={amountSelected.toString()}
                        onChange={e => {
                            let number = Math.max(parseInt(e.target.value),0);
                            setAmountSelected(number)
                            handleVerificationsAmountChange(paymentType, number);
                        }} required
                    >
                        {t('AMOUNT_VERIFICATIONS')}
                    </InputText>
                    {
                        working ?
                            <div className="d-flex justify-content-center">
                                <span className="loading loading-spinner loading-xs"></span>
                            </div> : null
                    }
                    {t('UNIT_PRICE')} <MoneyFormat>{prices.precioUnitarioIva}</MoneyFormat> {tenant.currency}
                    <div>
                        <br/>
                        <SelectPaymentType value={paymentType} action="CONSUMABLE"
                                           type="verificaciones"
                                           onChange={handlePaymentTypeChange}
                                           pendingPayments={false}
                                           askFacturacion={false}
                                           paymentMethods={tenant.paymentMethods}/>
                    </div>
                    <div>
                        {t('TOTAL_PRICE')} <MoneyFormat>{prices.totalIva}</MoneyFormat> {tenant.currency}
                    </div>
                </div>
                {
                    error ? <React.Fragment>
                        <span style={{fontWeight: 'bold', color: 'red'}}>{error}</span>
                        <br/>
                        <br/>
                    </React.Fragment> : null
                }
                <ConfirmationModal
                    isOpen={showSpeiModal}
                    onOk={handleSpeiSubmit}
                    onCancel={handleSpeiCancel}
                    title={'Pago por SPEI'}
                >
                    <p>
                        {t('PURCHASE_CONFIRMATION1')} <b>{amountSelected} {t('VERIFICATIONS')}</b> {t('USING')} <b>SPEI</b>.
                        <br/>
                        {t('PURCHASE_CONFIRMATION2')} <b>'{t('BUY')}'</b> {t('PURCHASE_CONFIRMATION3')} {t('VERIFICATIONS')} {t('PURCHASE_CONFIRMATION4')} <b>SPEI</b>
                    </p>
                    <p>{t('CONTINUE_QUESTION')}</p>
                </ConfirmationModal>
                <EmailNoticeSPEI
                    isOpen={noticeModal}
                    onClose={() => {
                        setShowSpeiModal(false)
                        setNoticeModal(false)
                        onSaved()
                    }}
                ></EmailNoticeSPEI>
                <div className={'modal-action'}>
                    <Button type="submit" disabled={working} color="primary">{t('PAY')} </Button>
                    <Button disabled={working} color="secondary" onClick={handleCancel}>{t('CANCEL')} </Button>
                </div>
            </form>
        </SimpleModal>
    )

}

export default BuyGlobalIdentityVerificationsModal;
