import React, {useState, useEffect} from 'react'
import axios from 'axios';
import {httpGet, backend} from '../shared/Config';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter,
    Form, FormGroup, Label, Input, FormText} from 'reactstrap'

import { useTranslation } from 'react-i18next';
import SimpleModal from "../shared/SimpleModal";
import InputText from "../shared/InputText";
import InputSelect from "../shared/InputSelect";
import { init } from 'i18next';

const defaultData = {
    name: '',
    rfc: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    postalCode: '',
    cfdiId: '',
    regimenFiscal: ''
}

function InvoiceDataModal({isOpen, initialState, onCancel, onSaved}) {
    const [iData, setIData] = useState(defaultData)
    const [working, setWorking] = useState(false);

    const {t} = useTranslation();

    useEffect(() => {
        if(initialState) {
            if(initialState.cfdiId == null) {
                initialState.cfdiId = '';
            }
        }
        setIData(initialState || defaultData);
    }, [isOpen, initialState])

    function handleCancel() {
        onCancel();
    }
    function handleTextChange(attr) {
        return function(event) {
            if(attr !== "cfdi") {
                setIData({...iData, [attr]:event.target.value})
            } else {
                setIData({...iData, [attr]:event.target.value, regimenFiscal: ""})
            }
        }
    }

    const [regimenFiscalesSelect, setRegimenFiscalesSelect] = useState();
    const [cfdiSelect, setCfdiSelect] = useState();


    useEffect(() => {
        async function fetchRegimenFiscales() {
            try {
                const response = await httpGet(`/getCFDIs`);
                setCfdiSelect(response.data.map((cfdiId) => <option key={cfdiId.id} value={cfdiId.code}>{cfdiId.desc}</option>));
            } catch(e) {
            }

        }
        fetchRegimenFiscales();
    }, [isOpen])

    useEffect(() => {
        async function fetchRegimenFiscales() {
            try {
                let code = "";
                if(iData.cfdiId?.code) {
                    code = iData.cfdiId?.code
                } else {
                    code = iData.cfdiId;
                }

                debugger
                const response = await httpGet(`/getRegimenFiscales/${code}`);
                setRegimenFiscalesSelect(response.data.map((regimenFiscal) => <option key={regimenFiscal.id} value={regimenFiscal.code}>{regimenFiscal.data}</option>));
            } catch(e) {
            }
        }
        if(iData.cfdiId) {
            fetchRegimenFiscales();
        }
    }, [iData.cfdiId])

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        setWorking(true);
        try {
            const response = await axios.post(backend('/invoiceData'), iData);
            setWorking(false);
            onSaved(response.data);
        } catch(e) {
            setWorking(false);
        }

    }

    return (
        <SimpleModal isOpen={isOpen}>
            <form onSubmit={handleSubmit}>
                <h3>
                    {t('BILLING_INFO')}
                </h3>
                <div>
                    <InputText
                        disabled={working}
                        value={iData.name}
                        onChange={handleTextChange('name')}
                        required>
                        {t('COMPANY_NAME')}
                    </InputText>

                    <InputText
                        disabled={working}
                        value={iData.rfc}
                        onChange={handleTextChange('rfc')}
                        required>
                        {t('RFC')}
                    </InputText>
                    <InputText
                        disabled={working}
                        value={iData.contactName}
                        onChange={handleTextChange('contactName')}
                        required>
                        {t('NAME')}
                    </InputText>
                    <InputText
                        disabled={working}
                        type={'email'}
                        value={iData.contactEmail}
                        onChange={handleTextChange('contactEmail')}
                        required>
                        {t('EMAIL')}
                    </InputText>
                    <InputText
                        type="number"
                        disabled={working}
                        value={iData.contactPhone}
                        onChange={handleTextChange('contactPhone')}
                        required>
                        {t('PHONE')}
                    </InputText>
                    <InputText
                        type="number"
                        disabled={working}
                        value={iData.postalCode}
                        onChange={handleTextChange('postalCode')}
                        required>
                        {t('POSTAL_CODE')}
                    </InputText>
                    <InputSelect
                        disabled={working}
                        label={t('CFDI_USE')}
                        value={iData.cfdiId.code}
                        onChange={handleTextChange('cfdiId')}
                        required>
                        <option value=""></option>
                        {cfdiSelect}
                    </InputSelect>
                    <InputSelect
                        label={t('REGIMEN')}
                        disabled={working}
                        value={iData.regimenFiscal.code}
                        onChange={handleTextChange('regimenFiscal')}
                        required>
                        <option value=""></option>
                        {regimenFiscalesSelect}
                    </InputSelect>
                </div>
                <div className={'modal-action'}>
                    <Button type="submit" disabled={working} color="primary">{t('SAVE')}</Button>
                    <Button disabled={working} color="secondary" onClick={handleCancel}>{t('CANCEL')}</Button>
                </div>
            </form>
        </SimpleModal>
    )
}

export default InvoiceDataModal;
