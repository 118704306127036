import React, {useState, useEffect} from 'react'
import {Button} from 'reactstrap';
import SimpleModal from "./SimpleModal";
import {Icon, InlineIcon} from "@iconify/react";

function SpeiInfoModal({isOpen, onClose}) {
    return (
        <SimpleModal isOpen={isOpen}>
            <h3>
                Información para transferencia
            </h3>
            <div>
                <div className="container">
                    <div className=" ">
                        <div className="prose">
                            <div className={'flex flex-col items-center align-middle'}>
                                <Icon
                                    icon={'ic:baseline-account-balance'} width={'50'} height={'50'}></Icon>
                            </div>
                            <p>
                                El pago de cualquier compra se realizará por medio de SPEI.
                                <span className="Bold"> Es importante que al momento de hacer la transferencia indiques la referencia que aparece en el detalle del pago</span>
                            </p>
                            <p>
                                Beneficiario<br/>
                                FIRMAMEX TECHNOLOGIES
                            </p>
                            <p>
                                Banco<br/>
                                HSBC
                            </p>
                            <p>
                                Cuenta<br/>
                                4062708029
                            </p>
                            <p>
                                CLABE<br/>
                                021180040627080294
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'modal-action'}>
                <Button className={'btn btn-primary'} color="primary" onClick={onClose}>Cerrar</Button>
            </div>
        </SimpleModal>
    )
}

export default SpeiInfoModal;
