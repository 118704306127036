import React, {useState, useEffect, useContext} from 'react';
import { httpGet, httpPost } from '../shared/Config';
import Section from '../shared/Section';
import Table from '../shared/Table'
import { Button, Alert, Spinner, FormGroup, Label, Input } from 'reactstrap';
import UserTokenModal from '../users/UserTokenModal';
import DeleteUserModal from '../users/DeleteUserModal';
import {ConsoleDispatch, types } from '../reducers/reducers'

import DateFormat from '../shared/DateFormat'
import moment from 'moment';

import StripeSubscriptionModal from '../payments/StripeSubscriptionModal';
import SpeiSubscriptionModal from '../payments/SpeiSubscriptionModal';
import ConfirmUnsubscribeModal from '../payments/ConfirmUnsubscribeModal';
import ConfirmReactivateModal from '../payments/ConfirmReactivateModal';


import { useTranslation } from 'react-i18next';
import InputText from "../shared/InputText";
import {Icon} from "@iconify/react";

function ActivateGlobalConsumption({orgId}) {

    const [currentStatus, setCurrentStatus] = useState(false);
    const [checkDone, setCheckDone] = useState(false);
    const [working, setWorking] = useState(false);

    function checkStatus(){
        setWorking(true);
        async function fetchStatus(){
            try{
                const response = await httpGet(`/orgs/${orgId}/globalConsumption`);
                if(response) {
                    setCurrentStatus(response.data.data.status);
                }
            } catch(error) {

            }
            setCheckDone(true);
            setWorking(false);
        }
        fetchStatus();
    }

    function handleSwitch(){
        setWorking(true);
        async function switchGlobalConsumption(){
            try{
                const response = await httpGet(`/orgs/${orgId}/switchGlobalConsumption`);
                if(response.data && response.data.data) {
                    setCurrentStatus(response.data.data.status);
                } else {
                    console.error(response.data.error);
                }
            } catch(error) {

            }
            setWorking(false);
        }
        switchGlobalConsumption();
    }

    useEffect(() => {
        checkStatus();
    }, []);

    return(
        <div>
            <div className="container">
                {
                    checkDone ? <React.Fragment>
                        <div className="row align-items-center">
                            <div className="col">
                                <div>
                                    <div>Consumo de documentos globales:</div>
                                    {
                                        currentStatus ? <React.Fragment><b>Activado</b></React.Fragment> : <React.Fragment><b>Desactivado</b></React.Fragment>
                                    }
                                </div>
                            </div>
                            <div className="col">
                                <div className="row justify-content-end">
                                    <Button outline
                                        onClick={handleSwitch}
                                        color="primary"
                                        disabled={working}>
                                        {
                                            currentStatus ? <React.Fragment>Desactivar</React.Fragment> : <React.Fragment>Activar</React.Fragment>
                                        }
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </React.Fragment> : <React.Fragment>
                        <div className="row align-items-center">
                            <div className="col">
                                <div>Hubo un error tratando de llenar esta seccion. Intente mas tarde.</div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}

function RemainingDocuments({remaining, planData, orgId, onDocumentsAdded}) {

    const [modal, setModal] = useState({
        open: false
    })

    function handleModalCancel() {
        setModal({open:false})
    }

    function handleAddDocsClick() {
        setModal({open:true})
    }

    function handleDocumentsAdded() {
        setModal({open:false});
        onDocumentsAdded();
    }

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    return (
        <div className="container">
            <div className="row align-items-center">
                <div className="col">
                    <div>Documentos restantes: {remaining.documents}</div>
                </div>
                {/*<div className="col">
                    <div className="row justify-content-end">
                    <Button outline
                        onClick={handleAddDocsClick}
                        color="primary">Agregar</Button>
                    </div>
                </div>*/}
            </div>
            {/*<BuyDocumentsModal
                orgId={orgId}
                isOpen={modal.open}
                onCancel={handleModalCancel}
                onSaved={handleDocumentsAdded}
            />*/}
        </div>
    )
}

function RemainingTimestamps({remaining, planData, orgId, onTimestampsAdded}) {

    const [status, setStatus] = useState({
        working: false,
        error: null,
        loading: false,
        stampsActive: false
    })

    useEffect(() => {
        async function fetchConfig() {
            try {
                setStatus({working: true, error: null, loading: true})
                const response = await httpGet(`/orgs/${orgId}/config/nom151FinalStamp`);
                //var planDataObj = planData;
                if(response.data.data){
                    const dataJson = response.data.data;
                    if(dataJson.stamp === true) {
                        setStatus({working: false, error: null, loading: false, stampsActive: true })
                    } else {
                        setStatus({working: false, error: null, loading: false, stampsActive: false })
                    }
                }
                //setStamper(serverConfig);
            } catch(e) {
                setStatus({working: false, error: 'No se pudo obtener la configuración, por favor reintente más tarde', loading: false})
            }
        }
        fetchConfig();
    }, [orgId])

    function handleActivateStamps() {
        async function fetchOrgData() {
            const response = await httpGet(`/orgs/${orgId}/config/activateTimestamps`)

            if(response.data) {
                const dataJson = response.data;
                if(dataJson.success) {
                    setStatus({working: false, error: null, loading: false, stampsActive: true });
                }
            }
            //setOrg(response.data);
        }
        fetchOrgData();
    }

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    return (
        <div className="container">
            {
                status.stampsActive ? <React.Fragment>
                {
                    planData.plan.name === "PERSONAL" ? <React.Fragment>
                        Plan 'Personal' de sellos NOM151 activo.
                    </React.Fragment> : null
                }
                {
                    planData.plan.name === "CORPORATIVO" ? <React.Fragment>
                        Plan 'ON DEMAND' de sellos NOM151 activo.
                    </React.Fragment> : null
                }
                {
                    planData.plan.name === "EMPRESARIAL" ? <React.Fragment>
                        Plan 'Empresarial' de sellos NOM151 activo.
                    </React.Fragment> : null
                }
                </React.Fragment> : <React.Fragment>
                    <div>Las sellos NOM151 estan desactivadas para esta cuenta.</div>
                    <div className="row justify-content-end" >
                        <Button outline
                            onClick={handleActivateStamps}
                            color="primary">Activar sellos NOM151</Button>
                    </div>
                </React.Fragment>
            }
        </div>
    )
}


function Organization({match}) {

    const {t} = useTranslation();

    const [org, setOrg] = useState({});
    const [orgError, setOrgError] = useState(false);
    const [orgData, setOrgData] = useState({});

    const [fetchComplete, setFetchComplete] = useState(false);
    //const [companyData, setCompanyData] = useState({});
    const [subType, setSubType] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [subStatus, setSubStatus] = useState('');
    const [errorCheckingActive, setErrorCheckingActive] = useState(false);
    const [validUntil, setValidUntil] = useState(false);
    const [validUntilNull, setValidUntilNull] = useState(false);

    const [waitingPayment, setWaitingPayment] = useState(false);

    const [planData, setPlanData] = useState({
        plan: {}
    });
    const [usersWorking, setUsersWorking] = useState(false);
    const [users, setUsers] = useState([])
    const [remainingUsers, setRemainingUsers] = useState({});
    const [sameEmail, setSameEmail] = useState(false);

    const [userModal, setUserModal] = useState({
        open: false
    })
    const [deleteUserModal, setDeleteUserModal] = useState({
        open: false,
        user: {}
    })

    const [workingAdmin, setWorkingAdmin] = useState({
        working: false
    })

    const [reactivateModal, setReactivateModal] = useState({
        open: false
    });

    const [unsubModal, setUnsubModal] = useState({
        open: false
    });

    const [unsubSpeiModal, setUnsubSpeiModal] = useState({
        open: false
    });

    const [subModal, setSubModal] = useState({
        open: false
    });

    const [speiModal, setSpeiModal] = useState({
        open: false
    });

    const [buyState, setBuyState] = useState({
        subType: '',
        newOrgId: match.params.id
    });

    const [unsubSuccess, setUnsubSuccess] = useState(false);
    const [unsubError, setUnsubError] = useState(false);
    const [unsubWorking, setUnsubWorking] = useState(false);

    const [reactivateSuccess, setReactivateSuccess] = useState(false);
    const [reactivateError, setReactivateError] = useState(false);
    const [reactivateWorking, setReactivateWorking] = useState(false);

    const [departmentName, setDepartmentName] = useState('');
    const [error, setError] = useState('');

    const dispatch = useContext(ConsoleDispatch)

    useEffect(() => {
        async function fetchOrgData() {
            setUsersWorking(true);
            try{
                const response = await httpGet(`/orgs/${match.params.id}`)
                if(response.data) {
                    setOrg(response.data);
                }

            } catch(error){
                setOrgError(true);
            }
        }
        fetchOrgData();
    }, [match.params.id])

    useEffect(() => {
        async function fetchPlan() {
            const response = await httpGet(`/orgs/${match.params.id}/plan`);

            if(response) {
                setPlanData(response.data);
                setBuyState({...buyState, subType: response.data.plan.name})

                try{
                    if(response.data.plan.name === "PERSONAL") {
                        setSubType(true);

                        if(org.subscriptionType === "STRIPE"){
                            const responseActive = await httpGet(`/payments/${match.params.id}/checkIfActiveStripe`)

                            if(responseActive) {
                                let status = responseActive.data.data.status;
                                setIsActive(status);

                                if(responseActive.data.data.statusType) {
                                    setSubStatus(responseActive.data.data.statusType);
                                }

                                if(responseActive.data.data.validUntil) {
                                    setValidUntil(responseActive.data.data.validUntil);
                                } else {
                                    setValidUntil(null);
                                }
                            } else {
                                setIsActive(false);
                                setErrorCheckingActive(true);
                            }
                        } else if(org.subscriptionType === "SPEI") {
                            const responseActive = await httpGet(`/payments/${match.params.id}/checkIfActiveSPEI`)

                            if(responseActive) {
                                let status = responseActive.data.data.status;
                                setIsActive(status);

                                if(responseActive.data.data.statusType) {
                                    setSubStatus(responseActive.data.data.statusType);
                                }

                                if(responseActive.data.data.validUntil) {
                                    if(responseActive.data.data.validUntil === "null"){
                                        setValidUntilNull(true);
                                    } else {
                                        setValidUntil(responseActive.data.data.validUntil);
                                    }
                                } else {
                                    setValidUntil(null);
                                }

                                if(responseActive.data.data.waitingPayment) {
                                    setWaitingPayment(true);
                                }
                            } else {
                                setIsActive(false);
                                setErrorCheckingActive(true);
                            }
                        } else {
                            setIsActive(true);
                            setSubType(false);
                        }
                    } else {
                        setIsActive(true);
                        setSubType(false);
                    }

                    setFetchComplete(true);
                } catch(error) {
                    setIsActive(false);
                    setErrorCheckingActive(true);
                }
            }
        }

        async function fetchUsers() {
            const response = await httpGet(`/orgs/${match.params.id}/users`);
            setUsers(response.data.map(u => ({...u, ...JSON.parse(u.json)})));
            setUsersWorking(false);

        }
        dispatch({type:types.UPDATE_PAYMENT_INFO})
        if(org.subscriptionType){
            fetchPlan();
            fetchUsers();
        }

    }, [org])


    useEffect(() => {
        async function fetchRemainingUsers() {
            const response = await httpGet(`/orgs/${match.params.id}/users/remaining`);
            setRemainingUsers({...response.data})
        }

        fetchRemainingUsers();
    }, [users.length, match.params.id])

    function handleUserAdd() {
        setUserModal({...userModal, open:true})
    }

    function handleUserSave(user) {
        setUsers([...users, user])
        setUserModal({open:false})
    }

    function handleDeleteUserClick(user) {
        return function() {
            setDeleteUserModal({
                user, open:true
            })
        }
    }

    function handleUserDeleted(user) {
        setUsers(users.filter(u => u.id !== user.id))
        setDeleteUserModal({user:{}, open:false})
    }

    function requestChangeDepartmentName() {
        setError('');
        async function changeDepartmentName() {
            let trimName = departmentName.trim();
            const response = await httpPost(`/orgs/changeDepartmentName`, {departmentName: trimName, id: match.params.id});
            if(response) {
                if(response.data.status === "success") {
                    setOrg({...org, organizationName: trimName});
                    setDepartmentName('');
                } else if(response.data.status === "name_found") {
                    setError("ERROR_SAME_NAME");
                } else if(response.data.status === "too_long") {
                    setError("ERROR_NAME_TOO_LONG");
                }
            }
        }
        changeDepartmentName();
    }

    function handleDepartmentName(attr) {
        return function(event) {
            if(event.target.value.length < 25) {
                setDepartmentName(event.target.value);
            }
        }
    }

    function handleAdminChange(e, user) {
        async function adminToggle() {
            try{
                setWorkingAdmin({working: true});
                const response = await httpPost(`/orgs/${match.params.id}/users/admin`, user);
                //if(response.data && response.data.success) {
                if(response.data && response.data.data) {
                    //const json = JSON.parse(response.data);
                    setUsers(users.map(userIt => {
                        if(user.id === userIt.id) {
                            return {...userIt, adminCore: response.data.data.status}
                        }
                        return userIt;
                    }));
                } else {

                }
            } catch(error) {

            }

            setWorkingAdmin({working: false})
        }
        adminToggle();
    }

    function speiInfoModal() {
        setSpeiModal({open:true});
    }

    function closeSpeiInfoModal() {
        setSpeiModal({open:false}); // closes the modal, the user won't be able to open the department until the sub is complete
        window.location.reload();
    }


    function activateDepartment() {
        setSubModal({open:true});
    }

    function activateDepartmentSPEI() {
        setSubModal({open:true});
    }

    function cancelSub() {
        setSubModal({open:false}); // closes the modal, the user won't be able to open the department until the sub is complete
    }

    function cancelSubSPEI() {
        setSubModal({open:false}); // closes the modal, the user won't be able to open the department until the sub is complete
    }

    function successSub() {
        setSubModal({open:false});
        window.location.reload();
    }

    function unsubscribe() {
        setUnsubModal({open:true});
    }

    function unsubModalCancel() {
        setUnsubModal({open:false});
        window.location.reload();
    }


    function unsubConfirm() {
        setUnsubWorking(true);
        setUnsubError(false);

        async function unsub(){
            if(org.subscriptionType === "STRIPE") {
                try{
                    const response = await httpPost(`/payments/subscription/${match.params.id}/cancelSubscription/`);
                    if(response) {
                        setUnsubSuccess(true);
                    } else {
                        setUnsubError(true);
                    }
                }
                catch(error) {
                    setUnsubError(true);
                }
            } else if(org.subscriptionType === "SPEI") {
                try{
                    const response = await httpPost(`/payments/subscription/${match.params.id}/cancelSPEISubscription/`);
                    if(response) {
                        setUnsubSuccess(true);
                    } else {
                        setUnsubError(true);
                    }
                }
                catch(error) {
                    setUnsubError(true);
                }
            }

            setUnsubWorking(false);
        }
        unsub();
    }

    function reactivateModalOpen() {
        setReactivateModal({open:true});
    }

    function reactivateModalCancel(){
        setReactivateModal({open:false});
        window.location.reload();
    }

    function reactivateConfirm() {
        setReactivateWorking(true);
        setReactivateError(false);

        async function reactivate(){
            if(org.subscriptionType === "STRIPE") {
                try{
                    const response = await httpPost(`/payments/subscription/${match.params.id}/reactivateSubscription/`);
                    if(response) {
                        setReactivateSuccess(true);
                    } else {
                        setReactivateError(true);
                    }
                }
                catch(error) {
                    setReactivateError(true);
                }
            } else if(org.subscriptionType === "SPEI") {
                try{
                    const response = await httpPost(`/payments/subscription/${match.params.id}/reactivateSPEISubscription/`);
                    if(response) {
                        setReactivateSuccess(true);
                    } else {
                        setReactivateError(true);
                    }
                }
                catch(error) {
                    setReactivateError(true);
                }
            }
            setReactivateWorking(false);
        }
        reactivate();
    }

    return (
        <div>
            {
                !orgError && fetchComplete ? <React.Fragment>
                    {
                        isActive ? <React.Fragment> {/* aun no se obtiene la informacion de la subscripcion */}
                            {
                                subStatus === "ACTIVE" ? <React.Fragment>
                                        {t('ACTIVE_DEPARTMENT')}
                                        <br/>
                                    </React.Fragment> : null
                            }
                            {
                                subStatus === "PROCESSING" ? <React.Fragment>
                                    <Section>
                                        {t('ACTIVATION_PROCESS')}
                                        <br/>
                                        {t('CONTINUE_USING')}
                                        <br/>
                                    </Section>
                                </React.Fragment> : null
                            }
                            {
                                subStatus === "ACTIVE_NO_RENEWAL" && !waitingPayment  ? <React.Fragment>
                                    <Section>
                                        <div className="column">
                                            {t('INACTIVE_SUBSCRIPTION')}
                                            <br />
                                            {t('SUBCRIPTION_INFO1')}
                                            <br/>
                                        </div>
                                        <div className="col">
                                            <div className="row justify-content-end">
                                                <Button  outline color="danger" onClick={reactivateModalOpen}>
                                                {t('RESTART_SUBSCRIPTION')}
                                                </Button>
                                            </div>
                                        </div>
                                    </Section>
                                    <ConfirmReactivateModal isOpen={reactivateModal.open}
                                        onCancel={reactivateModalCancel}
                                        onOk={reactivateConfirm}
                                        title="RESTART_SUBSCRIPTION"
                                        reactivateComplete={reactivateSuccess}
                                        reactivateWorking={reactivateWorking}>
                                            <p>{t('RESTART_SUBSCRIPTION_INFO1')}</p>
                                            <p>{t('CONTINUE_QUESTION')}</p>
                                            {
                                                reactivateSuccess ? <React.Fragment>
                                                    <span style={{fontWeight: 'bold', color: '#166fd0'}}>{t('REACTIVATION_COMPLETED')}</span>
                                                </React.Fragment> : null
                                            }
                                            {
                                                reactivateError ? <React.Fragment>
                                                    <span style={{fontWeight: 'bold', color: 'red'}}>{t('REACTIVATION_FAILED')}</span>
                                                </React.Fragment> : null
                                            }
                                    </ConfirmReactivateModal>
                                </React.Fragment> : null
                            }
                            {
                                subType ? <React.Fragment>
                                    {
                                        validUntilNull ? <React.Fragment>
                                            {t('SUBCRIPTION_INFO2')} <DateFormat>{moment(org.createdAt).add(3, 'days')}</DateFormat>
                                        </React.Fragment> : <React.Fragment>
                                            {
                                                validUntil ? <React.Fragment>
                                                    {t('BILLING_DATE')} <DateFormat>{moment(validUntil)}</DateFormat>
                                                </React.Fragment> : <React.Fragment>
                                                {t('COULDNT_GET_BILLING_DATE')}
                                                </React.Fragment>
                                            }
                                        </React.Fragment>
                                    }
                                </React.Fragment> : null
                            }
                            <h3>{t('DEPARTMENT')}: {org.organizationName}</h3>
                            {
                                users.length === 0 ?
                                <div role='alert' className={'alert mt-5 mb-5 cursor-pointer'}
                                     onClick={handleUserAdd}>
                                    {t('NO_USERS')}
                                </div>   :
                                null
                            }
                            {
                                sameEmail ?
                                    <div role={'alert'}
                                         className={'alert mt-5 mb-5'}>
                                        {t('SAME_EMAIL')}
                                    </div>
                                 : <React.Fragment>

                                </React.Fragment>
                            }
                            <Section>
                                <div>
                                    <InputText
                                        value={departmentName}
                                        onChange={handleDepartmentName('dep')} required>
                                        {t('CHANGE_DEPARTMENT_NAME', {
                                            orgName: org.organizationName
                                        })}
                                    </InputText>
                                    <button className={'btn btn-secondary mt-5'} onClick={requestChangeDepartmentName}>{t('CHANGE')}</button>
                                </div>
                                <br/>
                                {
                                     error ? <React.Fragment><span style={{fontWeight: 'bold', color: 'red'}}>{error}</span></React.Fragment> : null
                                }
                            </Section>
                            <br/>
                            <Section className={'overflow-auto'}>
                                <div className={'prose'}>
                                    <h3>{t('USERS')}</h3>
                                </div>
                                {
                                    planData.plan.name !== "PERSONAL" ?
                                        <div className="row justify-content-end">
                                            <button
                                                className={'btn btn-outline btn-primary mt-5 mb-5'}
                                                onClick={handleUserAdd} disabled={planData.plan.limitsUsers && remainingUsers.users === 0 && !planData.plan.payForUsers}>{t('ADD_USER')}</button>
                                        </div> : null
                                }
                                {
                                    usersWorking ? <React.Fragment>
                                    <div className="d-flex justify-content-center">
                                        <span className="loading loading-spinner loading-xs"></span>
                                    </div>
                                    </React.Fragment> :
                                        <Table collection={users} noData="NO_USERS_FOUND">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>{t('USER')}</th>
                                                    <th>{t('VALIDATED')}</th>
                                                    <th>{t('ADMIN_QUESTION')}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    users.map((u, i) => (
                                                        <tr key={u.id}>
                                                            <td>{i + 1}</td>
                                                            <td>
                                                                {
                                                                    u.certData != null ? !u.certData.includes('@') ? u.certData : '' : null
                                                                }<br/>
                                                                {u.email}
                                                            </td>
                                                            {
                                                                u.signed === true ? <td>{t('YES')}</td> : <td>{t('NO')}</td>
                                                            }
                                                            <td>
                                                                <div>
                                                                    <div className="form-control">
                                                                        <label className="label cursor-pointer">
                                                                            <input type="checkbox"
                                                                                   disabled={workingAdmin.working || !u.canDelete}
                                                                                   className="checkbox checkbox-primary"
                                                                                   value={u.adminCore}
                                                                                   checked={u.adminCore}
                                                                                   onChange={(e) => handleAdminChange(e, u)}/>{' '}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            {
                                                                u.canDelete ?
                                                                    <td style={{textAlign:'right'}}>
                                                                        <button className={'btn btn-square btn-outline'}
                                                                                onClick={handleDeleteUserClick(u)}>
                                                                            <Icon icon={'material-symbols:delete-forever-outline'}></Icon>
                                                                        </button>
                                                                    </td> : null
                                                            }
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                }

                            </Section>
                            {
                                subType ? <React.Fragment>
                                    {
                                        subStatus === "ACTIVE" && !waitingPayment ? <React.Fragment>
                                            <Section>
                                                <div className="column">
                                                    {t('CANCEL_NOTICE1')}
                                                    <br />
                                                </div>
                                                <div className="col">
                                                    <div className="row justify-content-end">
                                                        <Button  outline color="danger" onClick={unsubscribe}>
                                                        {t('CANCEL_SUBSCRIPTION')}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Section>
                                            <ConfirmUnsubscribeModal isOpen={unsubModal.open}
                                                onCancel={unsubModalCancel}
                                                onOk={unsubConfirm}
                                                title="Cancelar suscripción"
                                                unsubComplete={unsubSuccess}
                                                unsubWorking={unsubWorking}>
                                                    <p>{t('CANCEL_NOTICE2')}</p>
                                                    <p>{t('CONTINUE_QUESTION')}</p>
                                                    {
                                                        unsubSuccess ? <React.Fragment>
                                                            <span style={{fontWeight: 'bold', color: '#166fd0'}}>{t('CANCEL_COMPLETE')}</span>
                                                        </React.Fragment> : null
                                                    }
                                                    {
                                                        unsubError ? <React.Fragment>
                                                            <span style={{fontWeight: 'bold', color: 'red'}}>{t('CANCEL_ERROR')}</span>
                                                        </React.Fragment> : null
                                                    }
                                            </ConfirmUnsubscribeModal>
                                        </React.Fragment> : null
                                    }
                                </React.Fragment> : null
                            }
                            <UserTokenModal isOpen={userModal.open}
                                firstUser={users.length === 0}
                                onCancel={() => setUserModal({...userModal, open:false})}
                                onSaved={handleUserSave}
                                orgId={match.params.id}
                                planData={planData}
                            />
                            <DeleteUserModal isOpen={deleteUserModal.open}
                                onCancel={() => setDeleteUserModal({...deleteUserModal, open:false})}
                                onDeleted={handleUserDeleted}
                                user={deleteUserModal.user}
                                orgId={match.params.id}/>
                        </React.Fragment> : <React.Fragment>
                            {
                                subStatus === "PAST_DUE" ? <React.Fragment>
                                    <h3>{t('DEPARTMENT')}: {org.organizationName}</h3>
                                    <br/>
                                    <h4>{t('PLAN')} {planData.plan.uiName}</h4>
                                    <br/>
                                    {
                                        org.subscriptionType === "STRIPE" ? <React.Fragment>
                                            <Section>
                                                <div className="column">
                                                {t('INACTIVE_DEPARTMENT')} <DateFormat>{moment(validUntil)}</DateFormat>
                                                    <br />
                                                </div>
                                                <div className="col">
                                                    <div className="row justify-content-end">
                                                        <Button  outline color="danger" onClick={reactivateModalOpen}>
                                                            {t('RESTART_SUBSCRIPTION')}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Section>
                                            <ConfirmReactivateModal isOpen={reactivateModal.open}
                                                onCancel={reactivateModalCancel}
                                                onOk={reactivateConfirm}
                                                title="RESTART_SUBSCRIPTION"
                                                reactivateComplete={reactivateSuccess}
                                                reactivateWorking={reactivateWorking}>
                                                    <p>{t('RESTART_SUBSCRIPTION_INFO1')}</p>
                                                    <p>{t('CONTINUE_QUESTION')}</p>
                                                    {
                                                        reactivateSuccess ? <span style={{fontWeight: 'bold', color: '#166fd0'}}>{t('REACTIVATION_COMPLETED')}</span> : null
                                                    }
                                                    {
                                                        reactivateError ? <span style={{fontWeight: 'bold', color: 'red'}}>{t('REACTIVATION_FAILED')}</span> : null
                                                    }
                                            </ConfirmReactivateModal>
                                        </React.Fragment> : null
                                    }
                                    {
                                        org.subscriptionType === "SPEI" ? <React.Fragment>
                                            <Section>
                                                <div className="column">
                                                    {t('OVERDUE_SUBSCRIPTION')}
                                                    {t('INFO_CHECK')}
                                                    <br/>
                                                    {t('INACTIVE_DEPARTMENT2')} <DateFormat>{moment(validUntil)}</DateFormat>
                                                    <br />
                                                </div>
                                                <div className="col">
                                                    <div className="row justify-content-end">
                                                        <Button outline
                                                            onClick={speiInfoModal}
                                                            color="primary">{t('INFO_CHECK2')}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Section>
                                            <SpeiSubscriptionModal
                                                isOpen={speiModal.open}
                                                buyState={buyState}
                                                onCancel={closeSpeiInfoModal}
                                                id={match.params.id}
                                            />
                                        </React.Fragment> : null
                                    }
                                </React.Fragment> : null
                            }
                            {
                                subStatus === "NO_SUB" ? <React.Fragment>
                                    <h3>{t('DEPARTMENT')}: {org.organizationName}</h3>
                                    <br/>
                                    <h4>{t('PLAN')} {planData.plan.uiName}</h4>
                                    <br/>
                                    {
                                        org.subscriptionType === "STRIPE" ? <React.Fragment>
                                            <Section>
                                                <div className="column">
                                                    {t('NO_SUBSCRIPTION_FOR_DEPARTMENT')}
                                                    <br/>
                                                </div>
                                                <div className="col">
                                                    <div className="row justify-content-end">
                                                        <Button style={{marginRight: '5px'}} outline
                                                            onClick={activateDepartment}
                                                            color="primary">{t('ACTIVE_DEPARTMENT_WITH_STRIPE')}
                                                        </Button>
                                                        <Button outline
                                                            onClick={speiInfoModal}
                                                            color="primary">{t('ACTIVE_DEPARTMENT_WITH_SPEI')}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Section>
                                            <StripeSubscriptionModal
                                                isOpen={subModal.open}
                                                buyState={buyState}
                                                onCancel={cancelSub}
                                                onStripeApproved={successSub}
                                            />
                                            <SpeiSubscriptionModal
                                                isOpen={speiModal.open}
                                                buyState={buyState}
                                                onCancel={closeSpeiInfoModal}
                                                id={match.params.id}
                                            />
                                        </React.Fragment> : null
                                    }
                                    {
                                        org.subscriptionType === "SPEI" ? <React.Fragment>
                                            <Section>
                                                <div className="column">
                                                    {t('NO_SUBSCRIPTION_DETECTED')}
                                                    <br/>
                                                </div>
                                                <div className="col">
                                                    <div className="row justify-content-end">
                                                        <Button outline
                                                            onClick={speiInfoModal}
                                                            color="primary">{t('INFO_CHECK2')}
                                                        </Button>
                                                        <br/>
                                                        <br/>

                                                    </div>
                                                </div>
                                            </Section>
                                            <SpeiSubscriptionModal
                                                isOpen={speiModal.open}
                                                buyState={buyState}
                                                onCancel={closeSpeiInfoModal}
                                                id={match.params.id}
                                            />
                                        </React.Fragment> : null
                                    }
                                </React.Fragment> : null
                            }
                            {
                                subStatus === "CANCELLED" ? <React.Fragment>
                                    <h3>{t('DEPARTMENT')}: {org.organizationName}</h3>
                                    <br/>
                                    <h4>{t('PLAN')} {planData.plan.uiName}</h4>
                                    <br/>
                                    {
                                        org.subscriptionType === "STRIPE" ? <React.Fragment>
                                            <Section>
                                                <div className="column">
                                                    {t('SUBSCRIPTION_CANCELED')}
                                                    <br/>
                                                </div>
                                                <div className="col">
                                                    <div className="row justify-content-end">
                                                        <Button outline
                                                            onClick={activateDepartment}
                                                            color="primary">{t('START_NEW_SUBSCRIPTION')}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Section>
                                            <StripeSubscriptionModal
                                                isOpen={subModal.open}
                                                buyState={buyState}
                                                onCancel={cancelSub}
                                                onStripeApproved={successSub}
                                            />
                                        </React.Fragment> : null
                                    }
                                    {
                                        org.subscriptionType === "SPEI" ? <React.Fragment>
                                            <Section>
                                                <div className="column">
                                                    {t('INACTIVE_DEPARTMENT3')}
                                                    <br/>
                                                </div>
                                                <div className="col">
                                                    <div className="row justify-content-end">
                                                        <Button outline
                                                            onClick={speiInfoModal}
                                                            color="primary">{t('INFO_CHECK2')}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Section>
                                            <SpeiSubscriptionModal
                                                isOpen={speiModal.open}
                                                buyState={buyState}
                                                onCancel={closeSpeiInfoModal}
                                                id={match.params.id}
                                            />
                                        </React.Fragment> : null
                                    }
                                </React.Fragment> : null
                            }
                        </React.Fragment>
                    }
                </React.Fragment> : null
            }

        </div>
    )
}

export default Organization
