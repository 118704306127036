import React, {useState, useEffect} from 'react';
import Section from '../shared/Section';
import Table from '../shared/Table';
import {httpGet} from '../shared/Config';
import MoneyFormat from '../shared/MoneyFormat'
import DateFormat from '../shared/DateFormat'
import moment from 'moment';
import { Collapse, Button, Card, FormGroup, Label, Input } from 'reactstrap';
import InputSelect from "../shared/InputSelect";
import {useTranslation} from "react-i18next";

function PackagesBoughtInfoListSection({status, title, desc, hideIfNoData, typeString}) {

    const {t} = useTranslation();
    const [packages, setPackages] = useState([]);
    const [statusPackage, setStatusPackage] = useState(status);
    const [noPackages, setNoPackages] = useState('');

    useEffect(() => {
        setNoPackages('');
        async function fetchPackages() {
            const response = await httpGet(`/packages/${statusPackage}`);

            if(response.data.length > 0) {
                for(let pb of response.data) {
                    const expirationDate = new Date(pb.expirationDate);
                    const now = moment();
                    let result = moment(expirationDate).diff(now,'days')
                    pb.diff = result;
                }
                setPackages(response.data);
            }
            if(response.data.length === 0) {
                setPackages([]);
                setNoPackages(t())
            }
        }

        fetchPackages();
    }, [statusPackage]);

    function handleTypeChange() {
        return function(event) {
            setStatusPackage(event.target.value);
        }
    }

    return (
        <Section>
            <h4>{title}</h4>
                {desc}
                <div>
                    <InputSelect
                        label={t('Pack status')}
                        value={statusPackage}
                        onChange={handleTypeChange()}
                        required>
                        <option value="Todos">{t('All')}</option>
                        <option value="Activos">{t('Active plural')}</option>
                        <option value="Expirados">{t('Expired plural')}</option>
                    </InputSelect>
                </div>
                <br/>
                <div className={'overflow-auto'}>
                    <Table collection={packages} noData={t('No packs with this status')}>
                        <thead>
                        <tr>
                            <th>{t('Concept')}</th>
                            <th>{t('Bought date')}</th>
                            <th>{t('Expiration date')}</th>
                            <th>{t('Payment method')}</th>
                            <th>{t('Is it expired')}</th>
                            <th>{t('Expired reason')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            packages.map((p,i) => (
                                <tr key={i}>
                                    <td>
                                        {
                                            p.buyType === "DOCUMENTS" ? <React.Fragment>
                                                {p.amountBought} {t('DOCUMENTS')}
                                            </React.Fragment> : null
                                        }
                                    </td>
                                    <td><DateFormat>{p.payloadCompletionDate}</DateFormat></td>
                                    <td>{ !p.expired ? <React.Fragment>
                                            <DateFormat>{p.expirationDate}</DateFormat>
                                            {
                                                p.diff > 0 ? <React.Fragment>
                                                    <div style={{left: '10px'}}>(en {p.diff} dias)</div>
                                                </React.Fragment> : null
                                            }
                                        </React.Fragment> : null }
                                    </td>
                                    <td>
                                        {
                                            p.purchaseType === "COMPLEMENTARY" ? <React.Fragment>
                                                {t('COMPLEMENTARY_PACKAGE')}
                                            </React.Fragment> : <React.Fragment>{p.purchaseType}</React.Fragment>
                                        }
                                    </td>
                                    <td>
                                        {
                                            p.expired ? <React.Fragment>
                                                {t('YES')}
                                            </React.Fragment> : <React.Fragment>
                                                {t('NO')}
                                            </React.Fragment>
                                        }
                                    </td>
                                    <td>
                                        {
                                            p.expired ? <React.Fragment>
                                                {
                                                    p.amountBought == p.amountConsumed ? <React.Fragment>
                                                        {t('DEPLETED')}
                                                    </React.Fragment> : <React.Fragment>
                                                        {t('EXPIRATION_DATE_REACHED')}
                                                    </React.Fragment>
                                                }
                                            </React.Fragment> : null
                                        }

                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                </div>
        </Section>
    );
}

export default PackagesBoughtInfoListSection;
