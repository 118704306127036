import React from 'react';
import { useTranslation } from 'react-i18next';

function EmailRegistration() {
    const {t} = useTranslation();   
    return(
        <div className="container">
            <p>{t('EMAIL_REGISTRATION_COMPLETED')}</p>
        </div>
    )
}

export default EmailRegistration