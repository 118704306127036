import React, {useState, useEffect} from 'react'
import {httpGet, httpPost} from '../shared/Config';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter,
    Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import { useTranslation } from 'react-i18next';
import SimpleModal from "../shared/SimpleModal";
import InputSelect from "../shared/InputSelect";
import InputText from "../shared/InputText";

const defaultData = {
    certData: '',
    email: '',
    type: ''
}

const DataTypes = {
    RFC: 'RFC',
    EMAIL: 'Correo',
    PHONE: 'Teléfono móvil'
}

function UserTokenModal({isOpen, onCancel, onSaved, orgId, planData, firstUser}) {

    const {t} = useTranslation();

    const [user, setUser] = useState(defaultData)
    const [working, setWorking] = useState(false);

    const [serverConf, setServerConf] = useState({});

    function handleCancel() {
        setUser(defaultData);
        onCancel();
    }


    useEffect(() => {
        async function fetchServerConf() {
            try {
                const response = await httpGet('/getServerConf');
                if(response?.data.jsonData) {
                    let parseConf = JSON.parse(response.data.jsonData);
                    setServerConf(parseConf);
                    let value = user.type;
                }
            } catch(e) {
                console.error(e);
            }
        }
        fetchServerConf();
    }, []);

    function handleTextChange(attr) {
        return function(event) {
            setUser({...user, [attr]:event.target.value})
        }
    }

    function handleSelectChange(attr) {
        return function(event) {
            setUser({...user, type:event.target.value})
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        setWorking(true);
        try {
            const postData = user;
            if(user.type === 'EMAIL') {
                // si el tipo de autoridad es correo
                // asignamos el correo directamente como el dato
                // que buscamos en el certificado
                postData.certData = user.email;
            }
            const response = await httpPost(`/orgs/${orgId}/users/add`, postData);
            setWorking(false);
            if(response?.data.success) {
                setUser(defaultData);
                onSaved({...response.data.userToken, ...JSON.parse(response.data.userToken.json)});
            }
        } catch(e) {
            setWorking(false);
        }

    }

    return (
        <SimpleModal isOpen={isOpen}>
            <form onSubmit={handleSubmit}>
                <h3>
                    {t('ADD_USER')}
                </h3>
                <div>
                    {
                        planData.plan.limitsUsers && !firstUser ?
                        <p style={{fontWeight:'bold'}}>{t('ADD_USER_NOTICE')}</p> : null
                    }
                    <p>
                        {t('ADD_USER_DESCRIPTION1')} <br/>
                        {t('ADD_USER_DESCRIPTION2')}
                    </p>
                    <div>
                        <InputSelect label={t('CERTIFICATE_TYPE')}
                            value={user.type}
                            onChange={handleSelectChange('authority')}
                            required>
                            <option value=""></option>
                            { serverConf?.userMode === "core" ? <option value="EMAIL">{t('EMAIL')}</option> : null }
                            <option value="SAT">SAT</option>
                            { serverConf?.userMode === "chihuahua" ? <option value="Chihuahua">Chihuahua</option> : null }
                        </InputSelect>
                    </div>
                    {
                        user.type === 'SAT' || user.type === 'Chihuahua' ?
                            <InputText
                                value={user.certData}
                                onChange={handleTextChange('certData')} required>
                                {t('RFC')}
                            </InputText> : null
                    }
                    {
                        user.type ?
                            <InputText
                                value={user.email}
                                onChange={handleTextChange('email')} required>
                                {t('EMAIL')}
                            </InputText> : null
                    }
                </div>
                <div className={'modal-action'}>
                    <Button type="submit" disabled={working || !user.type} color="primary">{t('SAVE')}</Button>
                    <Button disabled={working} color="secondary" onClick={handleCancel}>{t('CANCEL')}</Button>
                </div>
            </form>
        </SimpleModal>
    )
}

export default UserTokenModal;
