import React, { useState, useEffect } from "react";
import {httpPost} from '../shared/Config';
import { Form, Button, Input } from 'reactstrap';
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

import { useTranslation } from 'react-i18next';

function StripeSubscriptionCheckout({buyState, onSucceeded, onWorking, onProccesEnd, orgData, planCost}) {
  const {t} = useTranslation();  
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  
  const [clientSecret, setClientSecret] = useState('');
  const [subcriptionId, setSubscriptionId] = useState('');

  const [paymentIntent, setPaymentIntent] = useState('');

  const [emailReceipt, setEmailReceipt] = useState('');
  const [name, setName] = useState('');

  const [alreadyActiveSub, setAlreadyActiveSub] = useState(false);
  const [errorPreparingCheckout, setErrorPreparingCheckout] = useState(false);
  const [checkoutIntentError, setCheckoutIntentError] = useState(false);
  const [payDeclined, setPayDeclined] = useState(false);

  const stripe = ""
  const elements = useElements();

  useEffect(() => {
    setProcessing(true);
    setPayDeclined(false);
    setErrorPreparingCheckout(false);
    setAlreadyActiveSub(false);

    async function intentPayment() {
      try{

        const response = await httpPost(`/payments/subscription/intentStripePayment`, {
            buyState // we calculate everything again during the intent, to prevent price manipulation
        });
    
        if(response.data.clientSecret) {
          setClientSecret(response.data.clientSecret);
          setSubscriptionId(response.data.subscriptionId);
        } else if(response.data.status) {
          if(response.data.status === "already_active_sub") {
            setAlreadyActiveSub(true);
          } else {
            console.log('error generando carrito');
            setErrorPreparingCheckout(true);
          }
        } else {
          console.log('error generando carrito');
          setErrorPreparingCheckout(true);
        }
        
        setProcessing(false);
      } catch(e) {
        console.log('error generando carrito');
        setCheckoutIntentError(true);
      }
    }

    intentPayment();
  }, [buyState]);

  const cardStyle = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: 'Arial, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#32325d"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      }
  };

  async function payIntent(event){
    event.preventDefault();
    setProcessing(true);
    onWorking(); // disabled close button
    if (!stripe || !elements) {
      return;
    }

    const payload = await stripe.confirmCardPayment(clientSecret, {
      receipt_email: emailReceipt,
      payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: name
          },
      }
    });

    if (payload.error) {
        if(payload.error.code === "card_declined") {
            setPayDeclined(true);
        }

        console.log('[error]', payload.error);
    } else {
      if(payload.paymentIntent.status === "succeeded"){
          setSucceeded(true);
          setPaymentIntent(payload.paymentIntent);

          const responseUpdate = await httpPost(`/payments/updateAccountStanding`, {
            clientSecret,
            buyState,
            orgData
          });

          if(responseUpdate && responseUpdate.data.status === "success") { // we got confirmation that the payment is set to pending
            onSucceeded(responseUpdate.data.newOrgId);
          }
      }
      console.log('[payload]', payload);
    }
    setProcessing(false);
    onProccesEnd(); // enables close button
  }

  async function onCardChange(event) {

  }

  function emailReceiptChange(event) {
      setEmailReceipt(event.target.value)
  }
  
  function nameChange(event) {
    setName(event.target.value)
}
  return (
      <React.Fragment>
          <Form onSubmit={payIntent}>
                <br/>             
                {
                  buyState.subType === "CORPORATIVO" ? <React.Fragment>
                    {t('STRIPE_SUBSCRIPTION_CHECKOUT1')}
                  </React.Fragment> : null
                }
                <br/>
                <Input type="text" id="name" value={name} onChange={nameChange} placeholder="Nombre en la tarjeta" required/>
                <br/>
                <Input type="email" id="receiptEmail" value={emailReceipt} onChange={emailReceiptChange} placeholder="" />
                <br/>
                <CardElement id="card" options={cardStyle} onChange={(e) => onCardChange(e)} />
                <div>
                </div>
                <div>
                    <Button style={{width: '100%', marginTop: '20px'}} disabled={processing || checkoutIntentError ||  succeeded} type="submit"  color="primary">{t('PAY')}</Button> 
                </div>
                {
                    succeeded ? <React.Fragment>
                    <br/>
                      {t('PAYMENT_ACCEPTED')}
                    </React.Fragment> : null 
                }
                {
                    payDeclined ? <React.Fragment>
                    <br/>
                    <span style={{color: 'red', fontWeight: 'bold'}}>{t('PAYMENT_DECLINED')}</span>
                    </React.Fragment> :
                    null
                }
                {
                    errorPreparingCheckout || checkoutIntentError ? <React.Fragment>
                    <br/>
                    <span style={{color: 'red', fontWeight: 'bold'}}>{t('SHOPPING_CART_ERROR')}</span>
                    </React.Fragment> : null
                }
                {
                    alreadyActiveSub ? <React.Fragment>
                    <br/>
                    <span style={{color: 'red', fontWeight: 'bold'}}>{t('SUBSCRIPTION_ALREADY_ACTIVE')}</span>
                    </React.Fragment> : null
                }
                {/*disabled={!formIncomplete}*/}
          </Form>
          <br/>
      </React.Fragment>
  );
}
export default StripeSubscriptionCheckout;