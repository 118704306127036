import React, { useState, useEffect } from "react";
import {httpPost, httpGet} from '../shared/Config';
import { Form, Button, Input, Label } from 'reactstrap';
import MoneyFormat from '../shared/MoneyFormat'
import DateFormat from '../shared/DateFormat'
import moment from 'moment';
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import NavItem from "reactstrap/lib/NavItem";
import { result } from "lodash";

function StripeDocumentsCheckout({buyState, months, discount, onSucceeded, onWorking, onProccesEnd, org, type}) {

  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState('');

  const [emailReceipt, setEmailReceipt] = useState('');
  const [name, setName] = useState('');

  const [errorPreparingCheckout, setErrorPreparingCheckout] = useState(false);
  const [checkoutIntentError, setCheckoutIntentError] = useState(false);
  const [payDeclined, setPayDeclined] = useState(false);

  //const [confirmMonths, setConfirmMonths] = useState(false);
  //const [planSelected, setPlanSelected] = useState(-1);
  //const [planIsSelected, setPlanIsSelected] = useState(false);
  //const [plansAvailable, setPlansAvailable] = useState([]);

  const [paymentIntentId, setPaymentIntentId] = useState('');
  const [noPlans, setNoPlans] = useState(false);
  
  const [monthlyPayConfirmation, setMonthlyPayConfirmation] = useState(false);
  const [monthlyPayConfirmationError, setMonthlyPayConfirmationError] = useState(false);

  //const [processingPay, setProcessingPay] = useState(false);

  const [resultState, setResultState] = useState({
    precioUnitarioMasIva: 0,
    precioPaqueteMasIva: 0,
    precioTotalIva: 0,
    mensualidadesMasIva: 0
  });

  const stripe = "";
  const elements = useElements();

  useEffect(() => {
    setProcessing(true);
    setPayDeclined(false);
    
    async function intentPayment() {
      if(buyState.totalGlobalDocuments > 0){
        try{
          const responseCalc = await httpPost('/calculateTotalDocuments', {
            buyState // calculate again
          });

          if(responseCalc) {
              setResultState(previousState => {
                  return {
                      ...previousState,
                      precioUnitarioMasIva: responseCalc.data.data.precioUnitarioIva,
                      precioPaqueteMasIva: responseCalc.data.data.precioPaqueteIva,
                      precioTotalIva: responseCalc.data.data.totalIva,
                      mensualidadesMasIva: responseCalc.data.data.mensualidadIva,
                  }
              })

              if(!buyState.monthly) {
                const response = await httpPost(`/payments/documents/intentStripePayment`, {
                  buyState, // we calculate everything again during the intent, to prevent price manipulation
                  org
                });
        
                if(response) {
                  setClientSecret(response.data.clientSecret);
                  setPaymentIntentId(response.data.paymentIntentId);
                } else {
                  setErrorPreparingCheckout(true);
                }
              }
              
              setProcessing(false);
          } else {
            setErrorPreparingCheckout(true);
          }

          //const paymentResponse = await httpGet(`/payments/findById/${paymentId}`);
          //setPaymentInfo(paymentResponse.data);

        } catch(e) {
          console.log('error generando carrito');
          setCheckoutIntentError(true);
        }

      }
    }

    intentPayment();
  }, [buyState, org]);

  const cardStyle = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: 'Arial, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#32325d"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      }
  };

  /*async function payIntentMonths(event) {
    event.preventDefault();
    setProcessing(true);
    onWorking(); // disabled close button
    setMonthlyPayConfirmationError(false);

    try {
      const selectReady = plansAvailable[planSelected];

      const responseMonths = await httpPost(`/payments/completeMonthsPay`, {
        payment_intent_id: paymentIntentId,
        selected_plan: selectReady
      });
  
      if(responseMonths) {
        setMonthlyPayConfirmation(true);

        const responseUpdate = await httpPost(`/payments/waitingConfirmation`, {
          clientSecret: paymentIntentId
        });

      } else {
        setMonthlyPayConfirmationError(true);
      }
    } catch(error) {
      console.log("error al comprar a meses")
      setMonthlyPayConfirmationError(true);
    }
  }*/

  async function payIntent(event){
    event.preventDefault();
    setProcessing(true);
    setMonthlyPayConfirmationError(false);
    setErrorPreparingCheckout(false);
    setNoPlans(false);
    onWorking(); // disabled close button
    if (!stripe || !elements) {
      return;
    }

    if(buyState.monthly) {
      const card = elements.getElement(CardElement);

      try{
        const {paymentMethod, error} = await stripe.createPaymentMethod({
          type: 'card',
          card: card,
          billing_details: { name: name }
        });

        if(!error) {
          const response = await httpPost(`/payments/documents/intentByInstallmentsDocuments`, {
            payment_method_id: paymentMethod.id,
            buyState, // we calculate everything again during the intent, to prevent price manipulation
            email: emailReceipt
          });
          
          if(response.data.result === "plans_available") {
            
            const responseConfirm = await httpPost(`/payments/completeMonthsPay`, {
              payment_intent_id: response.data.paymentIntentId,
              buyState,
              org
            });
  
            if(responseConfirm) {
              if(responseConfirm.data.status === "succeeded") {
                setSucceeded(true);
                onSucceeded();
              } else {
                setMonthlyPayConfirmationError(true);
              }
            } else {
              setMonthlyPayConfirmationError(true);
            }
          } else if(response.data.result === "no_plans") {
            setNoPlans(true);
          } else {
            setErrorPreparingCheckout(true);
          }
        } else {
          setErrorPreparingCheckout(true);
        }
      } catch(error) {
        console.log("error al comprar a meses")
        setMonthlyPayConfirmationError(true);
        //onSucceeded();
      }

    } else {
      const payload = await stripe.confirmCardPayment(clientSecret, {
        receipt_email: emailReceipt,
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: name
          },
        }
      });
  
      if (payload.error) {
        if(payload.error.code === "card_declined") {
            setPayDeclined(true);
        }
  
        console.log('[error]', payload.error);
        
      } else {
  
        if(payload.paymentIntent.status === "succeeded"){
          setSucceeded(true);
  
          const responseUpdate = await httpPost(`/payments/waitingConfirmation`, {
            clientSecret
          });
  
          onSucceeded();
        }
  
        console.log('[payload]', payload);
      }
    }

    setProcessing(false);
    onProccesEnd(); // enables close button
  }

  async function onCardChange(event) {

  }

  function emailReceiptChange(event) {
      setEmailReceipt(event.target.value)
  }

  function nameChange(event) {
      setName(event.target.value)
  }

  /*function planChanged(event) {
    setPlanSelected(event.target.value);
    let valueInt = parseInt(event.target.value);
    if(valueInt !== -1) {
      setPlanIsSelected(true);
    } else {
      setPlanIsSelected(false);
    }
  }*/

  return (
      <React.Fragment>
          <Form onSubmit={payIntent}>
              Pago de <b>{buyState.totalGlobalDocuments} documentos.</b>
              <br/>
              Costo total (MXN): <b><MoneyFormat>{resultState.precioTotalIva}</MoneyFormat> (incluye IVA)</b>
              <br/>
              {
                buyState.monthly ? <React.Fragment>
                  <br/>
                  Este cobro es mensual.
                  <br/>
                  Cobro mensual ({months} meses): <b><MoneyFormat>{resultState.mensualidadesMasIva}</MoneyFormat> (incluye IVA)</b>
                  {
                    discount !== 0 ? <React.Fragment>
                        <br/>
                        Descuento de <b>{discount}%</b> activo.
                      </React.Fragment> : null
                  }
                </React.Fragment> : <React.Fragment>
                  <br/>
                  Descuento de <b>{discount}</b>% activo.
                  <br/>
                  Cantidad que pagara: <b><MoneyFormat>{resultState.precioTotalIva}</MoneyFormat> (incluye IVA)</b>
                </React.Fragment>
              }
              <br/>
              <br/>
              <Input type="text" id="name" value={name} onChange={nameChange} placeholder="Nombre en la tarjeta" required/>
              <br/>
              <Input type="email" id="receiptEmail" value={emailReceipt} onChange={emailReceiptChange} placeholder="Correo al que se enviara el recibo de compra (opcional)" />
              <br/>
              <CardElement id="card" options={cardStyle} onChange={(e) => onCardChange(e)} />
              <div>
                <Button style={{width: '100%', marginTop: '20px'}} disabled={processing || checkoutIntentError ||  succeeded} type="submit"  color="primary">Pagar</Button> 
              </div>
              <div style={{marginTop: '10px'}}>
                Firmamex <b>NO</b> guardara tu información bancaria.
              </div>
              {
                succeeded ? <React.Fragment>
                  <br/>
                  {
                    type === "UPGRADE" ? <React.Fragment>
                      El pago ha sido aceptado. Su actualización a ON DEMAND, junto con sus documentos/estampillas, se activarán una vez que se haya procesado el pago (este proceso puede tomar unos cuantos minutos). 
                      <br/>
                      <br/>
                      Puedes cerrar la ventana.
                    </React.Fragment> : <React.Fragment>
                      El pago ha sido aceptado. Tus documentos serán agregados una vez que se haya procesado el pago (este proceso puede tomar unos cuantos minutos). 
                      <br/>
                      <br/>
                      Puedes cerrar la ventana.
                    </React.Fragment>
                  }
                </React.Fragment> : null 
              }
              {
                payDeclined ? <React.Fragment>
                  <br/>
                  <span style={{color: 'red', fontWeight: 'bold'}}>El pago fue declinado. Revise los fondos/credito de su tarjeta ó intente más tarde.</span>
                </React.Fragment> :
                null
              }
              {
                errorPreparingCheckout || checkoutIntentError ? <React.Fragment>
                  <br/>
                  <span style={{color: 'red', fontWeight: 'bold'}}>No se pudo generar su carrito. Intente más tarde.</span>
                </React.Fragment> : null
              }
              {/*disabled={!formIncomplete}*/}
              {
                monthlyPayConfirmationError ? <React.Fragment>
                  <br/>
                  <span style={{color: 'red', fontWeight: 'bold'}}>El pago fue declinado. Revise el credito disponible de su tarjeta ó intente más tarde.</span>
                </React.Fragment> : null
              }
              {
                noPlans ? <React.Fragment>
                  <br/>
                  <span style={{color: 'red', fontWeight: 'bold'}}>La tarjeta que ingreso no puede ser usada para pagos a meses. Cambie su método de pago ó ingrese otra tarjeta.</span>
                </React.Fragment> : null
              }
          </Form>
          <br/>
          {/*<Form onSubmit={payIntentMonths}>
          {
            confirmMonths ? <React.Fragment>
              <Input type="select" id="plan" value={planSelected}
                  onChange={planChanged}
                  disabled={!confirmMonths}
                  required
                >
                <option key={-1} value={-1}>Elija uno de los modelos de pago</option>
                {
                    plansAvailable.map((p, i) => (
                        <option key={i} value={i}>{p.count} meses sin intereses</option>
                    ))
                }
              </Input>
              <br/>
              Esta por pagar la cantidad de <b><MoneyFormat>{resultState.precioTotalIva}</MoneyFormat></b> a 12 meses sin intereses a <b><MoneyFormat>{resultState.mensualidadesMasIva}</MoneyFormat></b> mensuales, ¿desea continuar?
              <br/>
              <Button style={{width: '100%', marginTop: '20px'}} disabled={!planIsSelected || !paymentIntentId || monthlyPayConfirmation} type="submit"  color="primary">Confirmar</Button> 
            </React.Fragment> : null
          }
        </Form>*/}
      </React.Fragment>
  );
}
export default StripeDocumentsCheckout;