import React, {useState, useEffect, useRef} from 'react'
import {httpGet, httpPost} from '../shared/Config';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter,
    Form, FormGroup, Label, Input, Spinner,Table} from 'reactstrap';
import MoneyFormat from '../shared/MoneyFormat';
import ConfirmationModal from '../shared/ConfirmationModal';
import StripeDocumentsModal from '../payments/StripeDocumentsModal';
import SelectPaymentType from '../payments/SelectPaymentType';
import 'rc-slider/assets/index.css';
import EmailNoticeSPEI from '../payments/EmailNoticeSPEI';

import { useTranslation } from 'react-i18next';
import SimpleModal from "../shared/SimpleModal";
import InputText from "../shared/InputText";

const defaultState = {
    totalGlobalDocuments: 0,
    monthly: false,
    paymentType: 'SPEI',
    sub: 'Stripe0',
    type: 'documents'
}

function BuyGlobalDocumentsModal({isOpen, onCancel, onSaved, companyActive, type, org, pendingPayments, askFacturacion, tenant}) {

    const {t} = useTranslation();

    const [processingPayment, setProcessingPayment] = useState(false);
    const [showPaymentMethod, setShowPaymentMethod] = useState(true);


    const [error, setError] = useState('');
    const [working, setWorking] = useState(false);
    const [calcWorking, setCalcWorking] = useState(false);

    const [minimumNotReached, setMinimumNotReached] = useState(false);
    const [showConfModal, setShowConfModal] = useState(false);

    const [minimumCost, setMinimumCost] = useState(10); // SPEI minimum is $10 pesos
    const [months, setMonths] = useState(6);
    const [discount, setDiscount] = useState(0);


    const [paymentModal, setPaymentModal] = useState({
        open: false
    });

    const interval = useRef(setTimeout(() => {}, 0));


    const [buyState, setBuyState] = useState({
        totalGlobalDocuments: 0,
        monthly: false,
        paymentType: 'SPEI',
        sub: 'Stripe0',
        type: type
    });

    const [resultState, setResultState] = useState({
        precioUnitario: 0,
        precioUnitarioMasIva: 0,
        precioPaquete: 0,
        precioPaqueteMasIva: 0,
        precioTotal: 0,
        precioTotalIva: 0,
        mensualidades: 0,
        mensualidadesMasIva: 0,
        pagoUnaSolaExhibicion: 0,
        pagoUnaSolaExhibicionMasIva: 0,
        precioUnitarioTimestampsIva: 0,
        precioPaqueteTimestampsIva: 0
    });

    const [noticeModal, setNoticeModal] = useState({
        open: false
    });


    function handleBuyStateChange(name) {
        return function(event) {
            let value;
            if(event.target.value >= 0 && event.target.value.length < 10) {
                value = event.target.value;
                setBuyState({...buyState, [name]:parseInt(value)});
            }
        }
    }


    useEffect(() => {
        if(buyState.totalGlobalDocuments > 0) {
            //setUserInput(true);

            /*let minimumSet = 0;

            if(buyState.monthly) {
                minimumSet = 1000;
                setMinimumValue(1000);
            } else {
                minimumSet = 50;
                setMinimumValue(50);
            }*/

            //if(buyState.totalGlobalDocuments >= minimumSet) {
            setCalcWorking(true);
            //setMinimumDocuments(true);

            interval.current = setTimeout(() => {
                async function calculate() {
                    try {
                            const response = await httpPost('/calculateTotalDocuments', {
                                buyState
                            });

                            if(response) {
                                setResultState(previousState => {
                                    return {
                                        ...previousState,
                                        precioUnitario: response.data.data.precioUnitario,
                                        precioUnitarioMasIva: response.data.data.precioUnitarioIva,
                                        precioPaquete: response.data.data.precioPaquete,
                                        precioPaqueteMasIva: response.data.data.precioPaqueteIva,
                                        precioTotal: response.data.data.total,
                                        precioTotalIva: response.data.data.totalIva,
                                        mensualidades: response.data.data.mensualidad,
                                        mensualidadesMasIva: response.data.data.mensualidadIva,
                                        pagoUnaSolaExhibicion: response.data.data.soloPago,
                                        pagoUnaSolaExhibicionMasIva: response.data.data.soloPagoIva,
                                        precioUnitarioTimestampsMasIva: response.data.data.precioUnitarioTimestampsIva,
                                        precioPaqueteTimestampsMasIva: response.data.data.precioPaqueteTimestampsIva
                                    }

                                });
                            }

                            checkMininumThreshold( response.data.data.totalIva);
                            /*if(buyState.totalGlobalDocuments < 1000) {
                                setMonthlyMininumNotReached(true);
                            } else {
                                setMonthlyMininumNotReached(false);
                            }

                            if(buyState.totalGlobalDocuments < 50) {
                                setMininumNotReached(true);
                            } else {
                                setMininumNotReached(false);
                            }*/

                            //setChecked(true);
                            setCalcWorking(false);
                    } catch(e) {
                    }
                }

                calculate();
            }, 1000);
            /*} else {
                setCalcWorking(false);
                setMinimumDocuments(false);
            }*/
        } else {
            setResultState(previousState => {
                return {
                    ...previousState,
                    precioUnitario: 0,
                    precioUnitarioMasIva: 0,
                    precioPaquete: 0,
                    precioPaqueteMasIva: 0,
                    precioTotal: 0,
                    precioTotalIva: 0,
                    mensualidades: 0,
                    mensualidadesMasIva: 0,
                    pagoUnaSolaExhibicion: 0,
                    pagoUnaSolaExhibicionMasIva: 0
                }
            });
            //setMininumNotReached(true);
        }

        return () => {
            clearTimeout(interval.current);
        };
    }, [buyState]);

    async function payItem(event) {
        event.preventDefault();
        event.stopPropagation();

        //let org = buyState.org;
        if(buyState.paymentType.includes("Stripe")) {
            // setPaymentModal({...paymentModal, open: true});
            const response = await httpPost(`/payments/documents/intentStripePayment`, { buyState })
            if (response.data.error) {
                setError(response.data.error);
            } else if(response.data.checkoutUrl) {
                window.location.href = response.data.checkoutUrl;
            }
        } else {
            handleSpeiPay();
        }
    }

    function modalClose() {
        //setChecked(false);
        //setUserInput(false);
        //setBuyState({...buyState, totalGlobalDocuments:0, monthly: false });
        setPaymentModal({...paymentModal, open: false});
    }

    function stripeApproved() {
        //setChecked(false);
        //setUserInput(false);
        //setBuyState({...buyState, totalGlobalDocuments:0, monthly: false});
        setPaymentModal({...paymentModal, open: false}); // message confirmation
        setBuyState(defaultState);
        onSaved();
    }

    useEffect(() => {
        async function processSpeiPayment() {
            try {
                //setShowConfModal(false);
                const response = await httpPost(`/orgs/globalDocuments`, {
                    buyState,
                    org
                })
                const {success, error} = response.data;
                if(success) {
                    setNoticeModal({...noticeModal, open: true});
                    //setBuyState(defaultState);
                    //setShowConfModal(false);
                    //onSaved();
                } else {
                    console.log(error);
                }
                setWorking(false);
                setProcessingPayment(false);
            } catch(e) {
                setWorking(false);
                setProcessingPayment(false);
            }

            //setVirtualTotalDocuments(1);
            //let packageAmount = calculatePackageAmountType(1, false);
            //setDisplayTotalDocuments(packageAmount);
        }

        if(processingPayment){
            processSpeiPayment();
        }
    }, [processingPayment])


    function handleSpeiPurchaseClick(){
        if(showConfModal) {
            setProcessingPayment(true);
        }
    }

    function noticeClose() {
        setBuyState(defaultState);
        setShowConfModal(false);
        setNoticeModal({...noticeModal, open: false});
        onSaved();
    }

    function handleConfCancel() {
        setWorking(false);
        setShowConfModal(false);
    }

    function handleSpeiPay(event) {
        setWorking(true);
        setShowConfModal(true);
    }

    function handleCancel() {
        setBuyState(defaultState);
        onCancel();
    }

    function handlePaymentTypeChange(type) {
        //setPaymentType(type);
        let monthly = false;
        let minimum = 10;
        let months = 6;
        let discount = 0;

        let paymentTypeCheck = "SPEI";

        switch(type){
            case "SPEI":
                discount = 10;
                break;
            case "Stripe0":
                paymentTypeCheck = "Stripe0";
                discount = 6;
                break;
            default:
                break;
        }

        checkMininumThreshold(resultState.precioTotalIva);
        setMinimumCost(minimum);
        setMonths(months);
        setDiscount(discount);

        setBuyState({...buyState, paymentType:paymentTypeCheck, monthly: monthly});
    }

    function checkMininumThreshold(totalIva) {
        if(totalIva < minimumCost && totalIva !== 0) {
            setMinimumNotReached(true);
        } else {
            setMinimumNotReached(false);
        }
    }

    return (
        <SimpleModal isOpen={isOpen}>
            <form onSubmit={payItem}>
                <h3>
                    {t('BUY_DOCUMENTS')}
                </h3>
                <div>
                    <div>
                        {
                            pendingPayments ? <React.Fragment>
                                <div style={{marginBottom:'2%', fontWeight: 'bold', color: 'red'}}>{t('PENDING_PAYMENT_NOTICE')}</div>
                            </React.Fragment> : null
                        }
                        {
                            askFacturacion ? <React.Fragment>
                                <div style={{marginBottom:'2%', fontWeight: 'bold', color: 'red'}}>{t('NO_CONTACT_NOTICE')}</div>
                            </React.Fragment> : null
                        }
                        {
                            buyState.type === "UPGRADE" ? <React.Fragment>
                                <div style={{marginBottom:'2%'}}>{t('PURCHASE_INFO1')}</div>
                            </React.Fragment> : null
                        }
                        <div style={{marginBottom:'2%'}}>
                            {t('ALL_PRICES_INCLUDE_TAX_AND_EXPIRE', {
                                months: '15',
                                buyType: t('documentos')
                            })}
                        </div>
                    </div>
                        <InputText
                            disabled={pendingPayments || askFacturacion }
                            type="number"
                            value={buyState.totalGlobalDocuments}
                            onChange={handleBuyStateChange('totalGlobalDocuments')}
                            required>
                            {t('AMOUNT_DOCUMENTS')}
                        </InputText>
                        {
                            !companyActive ? <React.Fragment>
                                <br/>
                                <p style={{fontWeight: 'bold', color: 'red'}}>{t('COMPANY_DEACTIVATED')}</p>
                            </React.Fragment> : null
                        }
                        {
                            calcWorking ? <React.Fragment>
                                { /* calcWorking */}
                                <div className="d-flex justify-content-center">
                                    <br/>
                                    <span className="loading loading-spinner loading-xs"></span>
                                </div>
                            </React.Fragment> : null
                        }
                        <div style={{marginTop:'2%'}}>
                            {t('UNIT_PRICE')} <MoneyFormat>{resultState.precioUnitarioMasIva}</MoneyFormat> { tenant.currency }
                            <br/>
                            {t('UNIT_PRICE_INFO')}
                            <br/>
                        </div>
                    {
                        showPaymentMethod ? <div>
                            <div>
                                <SelectPaymentType value={buyState.paymentType} subValue={buyState.sub} action="CONSUMABLE" type="documentos" onChange={handlePaymentTypeChange} pendingPayments={pendingPayments} askFacturacion={askFacturacion} paymentMethods={tenant.paymentMethods} />
                            </div>
                        </div> : null
                    }
                    <FormGroup>
                    {t('TOTAL_PRICE')}  <MoneyFormat>{resultState.precioTotalIva}</MoneyFormat> { tenant.currency }
                        {
                            buyState.monthly ? <React.Fragment>
                                <br/>
                                {t('MONTHLY_PAYMENTS')} <MoneyFormat>{resultState.mensualidadesMasIva}</MoneyFormat> { tenant.currency }
                                <br/>
                            </React.Fragment> : null
                        }
                        {
                            minimumNotReached ? <React.Fragment>
                                <br/>
                                <p style={{fontWeight: 'bold', color: 'red'}}>{t('MINIMUM_MONTHS1')} <b>{months}</b> {t('MINIMUM_MONTHS2')} <MoneyFormat>{minimumCost}</MoneyFormat> { tenant.currency }</p>
                            </React.Fragment> : null
                        }
                    </FormGroup>
                </div>
                {
                    error ? <React.Fragment>
                        <span style={{fontWeight: 'bold', color: 'red'}}>{error}</span>
                        <br/>
                        <br/>
                    </React.Fragment> : null
                }
                <div className={'modal-action'}>
                    <Button className={'btn btn-primary'} type="submit" disabled={calcWorking || working || minimumNotReached || !buyState.paymentType || !companyActive || resultState.precioTotalIva <= 0 || pendingPayments} color="primary">{t('PAY')} </Button>
                    <Button className={'btn'} disabled={calcWorking || working} color="secondary" onClick={handleCancel}>{t('CANCEL')}</Button>
                </div>
            </form>
            <StripeDocumentsModal
                isOpen={paymentModal.open}
                buyState={buyState}
                months={months}
                discount={discount}
                onCancel={modalClose}
                onStripeApproved={stripeApproved}
                org={org}
                type={type}
                />
            <ConfirmationModal isOpen={showConfModal}
                onCancel={handleConfCancel}
                onOk={handleSpeiPurchaseClick}
                title="BUY_DOCUMENTS_PACKAGE">
                <p>
                    {t('PURCHASE_CONFIRMATION1')} <b>{buyState.totalGlobalDocuments} {t('DOCUMENTS')} </b> {t('USING')} <b>SPEI</b>.
                    <br/>
                    {t('PURCHASE_CONFIRMATION2')} <b>'{t('BUY')}'</b> {t('PURCHASE_CONFIRMATION3')} {t('DOCUMENTS')} {t('PURCHASE_CONFIRMATION4')} <b>SPEI</b></p>
                <p>{t('CONTINUE_QUESTION')}</p>
            <EmailNoticeSPEI
                isOpen={noticeModal.open}
                onClose={noticeClose}
            />
            </ConfirmationModal>
        </SimpleModal>
    )
}

export default BuyGlobalDocumentsModal
