import React, {useState, useEffect} from 'react'
import {httpGet} from '../shared/Config';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter,
    Form} from 'reactstrap';


function DepartmentRequestModal({isOpen, onCancel, companyActive}) {

    const [working, setWorking] = useState(false); 
    const [checked, setChecked] = useState(false); 
    const [sentAlready, setSentAlready] = useState(false);
    const [error, setError] = useState(false);
    const [sent, setSent] = useState(false);

    function handleCancel() {
        onCancel();
    }

    useEffect(() => {
        async function checkIfSent() {
            try{
                const response = await httpGet('/checkIfSent');
                if(response.data) {
                    setChecked(true);
                    setSentAlready(response.data.data.status);
                } else {
                    setError(true);
                }
            } catch(err) {
                setChecked(false);
                setError(true);
            }
        }

        checkIfSent();
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        setWorking(true);
        try {
            const response = await httpGet('/requestCustomization');
            setWorking(false);
            if(response.data && response.data.data) {
                if(response.data.data.status === "sent") {
                    setSent(true);
                } else if(response.data.data.status === "error") {
                    setError(true);
                } else {
                    setError(true);
                }
            } else {
                console.error(response.data.error);
            }
        } catch(e) {
            setWorking(false);
            setError(true);
        }
    }

    return (
        <Modal isOpen={isOpen}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    Solicitar personalización
                </ModalHeader>
                <ModalBody>           
                    <p>¿Desea solicitar personalización de logos, dominio y almacenamiento privado? </p>
                    <p>
                        Al presionar 'Solicitar', el equipo de Firmamex se comunicará con usted en las proximas 24 horas para ver los detalles de la solicitud.
                    </p>
                    {
                        sentAlready && checked ? <React.Fragment>
                            <p style={{fontWeight: 'bold', color: 'red'}}>Su petición de personalización ya ha sido enviada. Por favor, espere respuesta de nuestro equipo. Gracias.</p>
                        </React.Fragment> : null
                    }
                    {
                        error ? <React.Fragment>
                            <p style={{fontWeight: 'bold', color: 'red'}}>Hubo un error en el sistema, por favor intente mas tarde.</p>
                        </React.Fragment> : null
                    }
                    {
                        !companyActive ? <React.Fragment>
                            <p style={{fontWeight: 'bold', color: 'red'}}>Su compañía ha sido desactivada. Por favor, contáctenos para más información.</p>
                        </React.Fragment> : null
                    }
                    {
                        sent ? <React.Fragment>
                            <p style={{fontWeight: 'bold', color: '#166fd0'}}>Se ha enviado el correo. Puede cerrar la ventana.</p>
                        </React.Fragment> : null
                    }
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" disabled={working || !checked || sentAlready || error || sent || !companyActive} color="primary">Solicitar</Button>
                    <Button disabled={working} color="secondary" onClick={handleCancel}>
                    {
                        sent ? <React.Fragment>
                            Cerrar
                        </React.Fragment> : <React.Fragment>
                            Cancelar
                        </React.Fragment>
                    }
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

export default DepartmentRequestModal;