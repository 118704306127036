import React, {useEffect, useRef} from 'react'

export default function SimpleModal({isOpen, children}) {


    const modalRef = useRef(null);
    const preventClosing = true;

    useEffect(() => {
        modalRef.current.addEventListener('keydown', (event) => {
            if (event.key === 'Escape' && preventClosing) {
                event.preventDefault();
            }
        });
    }, [])

    useEffect(() => {
        if(!modalRef.current) {
            return;
        }
        if(isOpen) {
            modalRef.current.showModal();
        } else {
            modalRef.current.close();
        }
    }, [isOpen])

    return (
        <dialog ref={modalRef} className={'modal'}>
            <div className={'prose modal-box'}>
                {
                    isOpen && children
                }
            </div>
        </dialog>
    )

}
