import React, {useState, useEffect} from 'react';
import SpeiLogo from '../icons/Spei_Logo2.png'
import StripeLogo from '../icons/Stripe_Logo2.png'
import {Label, FormGroup, Input} from 'reactstrap';
import { useTranslation } from 'react-i18next';

function PaymentIcon({name, value, selected, onClick}) {
    const {t} = useTranslation()
    function handleClick() {
        onClick(value);
    }

    //function handleMoreInfoClick() {
    //    onMoreInfoClick(value);
   // }

    return (
        <div className={`column PlanIcon ${selected ? 'Selected' : ''}`} onClick={handleClick}>
            {
                name === "Stripe" ? <React.Fragment>
                    <img src={StripeLogo} alt="stripelogo" />
                    <div>{t('PAY_WITH_CARD')}</div>
                </React.Fragment> : null
            }
            {
                name === "SPEI" ? <React.Fragment>
                    <img src={SpeiLogo} alt="speilogo" />
                    <div>SPEI</div>
                </React.Fragment> : null
            }
            {/*<div
                style={{color:'#007bff'}}
                onClick={handleMoreInfoClick}>Más info</div>*/}
        </div>
    )
}

function SelectPaymentType({value, subValue, type, action, onChange, pendingPayments, askFacturacion, paymentMethods, speiDiscount = true}) {

    const {t} = useTranslation()
    const [selected, setSelected] = useState({name:value, sub: subValue})
    const [stripeSelected, setStripeSelected] = useState(false);

    function handleClick(event) {
        if(event.target.value === "Stripe0") {
            setStripeSelected(true);
        } else {
            setStripeSelected(false);
        }

        setSelected({...selected, name: event.target.value});
        onChange(event.target.value);
    }

    useEffect(() => {
        let defaultPaymentMethod = paymentMethods[0]; // gets the first one from the list
        setSelected({...selected, name: defaultPaymentMethod});
    }, [paymentMethods]);



    return (
        <React.Fragment>
            <p>{t('SELECT_PAYMENT_METHOD')}</p>
            <div className="flex" >
                {
                    action === "CONSUMABLE" ?
                        <div >
                            {
                                paymentMethods.includes("SPEI") ? <React.Fragment>
                                    <div className="form-control">
                                        <label className="label cursor-pointer">
                                            <span className="label-text">{t('SPEI_TRANSFER_DISCOUNT')}</span>
                                            <input className="radio radio-primary"
                                                   disabled={pendingPayments || askFacturacion}
                                                   name="radio1"
                                                   type="radio"
                                                   value="SPEI"
                                                   checked={"SPEI" === selected.name}
                                                   onChange={handleClick}/>{' '}
                                        </label>
                                    </div>
                                    <div style={{marginBottom: '2%'}}>
                                        {t('SPEI_NOTICE1', {
                                            paymentMethod: 'SPEI',
                                            unverifiedPercent: '10%',
                                            buyType: t(type),
                                            maxPercent: '50%'
                                        })}
                                    </div>
                                </React.Fragment> : null
                            }
                            {
                                paymentMethods.includes("Stripe0") ?
                                    <div className="form-control">
                                        <label className="label cursor-pointer">
                                            <span className="label-text">
                                            {t('PAY_WITH_CARD')} con STRIPE</span>
                                            <input className="radio radio-primary"
                                                   disabled={pendingPayments || askFacturacion}
                                                   name="radio1" type="radio"
                                                   value="Stripe0"
                                                   checked={"Stripe0" === selected.name}
                                                   onChange={handleClick} />{' '}
                                        </label>
                                    </div>
                               : null
                            }
                        </div>
                        : <FormGroup tag="fieldset">
                            {
                                paymentMethods.includes("SPEI") ?
                                    <div className="form-control">
                                        <label className="label cursor-pointer">
                                            <span className="label-text">{t('SPEI_TRANSFER')}</span>
                                            <input className="radio radio-primary"
                                                   disabled={pendingPayments || askFacturacion}
                                                   name="radio1"
                                                   type="radio"
                                                   value="SPEI"
                                                   checked={"SPEI" === selected.name}
                                                   onChange={handleClick}/>{' '}
                                        </label>
                                    </div>
                                : null
                            }
                            {
                                paymentMethods.includes("Stripe0") ?
                                    <div className="form-control">
                                        <label className="label cursor-pointer">
                                            <span className="label-text">
                                            {t('PAY_WITH_CARD')} con STRIPE</span>
                                            <input className="radio radio-primary"
                                                   disabled={pendingPayments || askFacturacion}
                                                   name="radio1" type="radio"
                                                   value="Stripe0"
                                                   checked={"Stripe0" === selected.name}
                                                   onChange={handleClick} />{' '}
                                        </label>
                                    </div>
                                : null
                            }
                        </FormGroup>
                }

            </div>
        </React.Fragment>
    )
}

export default SelectPaymentType
