import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Label, Input, FormGroup } from 'reactstrap';
import axios from 'axios';
import {httpGet, backend} from '../shared/Config';

const defaultData = {
    name: '',
    rfc: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    postalCode: '',
    cfdiId: '',
    regimenFiscal: ''
}

function UpdateRegimenFiscal({isOpen, invoiceId, onSaved, askFacturacion}) {

    const [data, setData] = useState(defaultData);
    const [working, setWorking] = useState(false);

    function handleTextChange(attr){
        return function(event) {
            setData({...data, [attr]:event.target.value})
        }
    }

    const [regimenFiscalesSelect, setRegimenFiscalesSelect] = useState();
    const [cfdiSelect, setCfdiSelect] = useState();

    useEffect(() => {
        async function fetchRegimenFiscales() {
            try {
                const response = await httpGet(`/getCFDIs`);
                setCfdiSelect(response.data.map((cfdiId) => <option key={cfdiId.id} value={cfdiId.code}>{cfdiId.desc}</option>));
            } catch(e) {
            }

        }
        fetchRegimenFiscales();
    }, [isOpen])

    useEffect(() => {
        async function fetchRegimenFiscales() {
            try {
                debugger
                const response = await httpGet(`/getRegimenFiscales/${data.cfdiId}`);
                setRegimenFiscalesSelect(response.data.map((regimenFiscal) => <option key={regimenFiscal.id} value={regimenFiscal.code}>{regimenFiscal.data}</option>));
            } catch(e) {
            }
        }
        if(data.cfdiId) {
            fetchRegimenFiscales();
        }
    }, [data.cfdiId])


    useEffect(() => {
        setWorking(true);
        if(invoiceId && invoiceId !== 0) {
            async function fetchInvoice() {
                const response = await httpGet(`/invoiceData/${invoiceId}`);
                if(response.data) {
                    setData({...response.data, name: response.data.name, rfc: response.data.rfc, email: response.data.email, postalCode: response.data.postalCode, cfdi: response.data.cfdi, regimenFiscal: '' });
                }
                setWorking(false);
            }
            fetchInvoice();
        } else {
            setWorking(false);
        }
    }, [invoiceId]);

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        setWorking(true);
        try {
            const response = await axios.post(backend('/invoiceData'), data);
            setWorking(false);
            onSaved(response.data);
        } catch(e) {
            setWorking(false);
        }
    }

    return (
        <Modal isOpen={isOpen}>
                <Form onSubmit={handleSubmit}>
                    <ModalHeader>{
                        !askFacturacion ? <React.Fragment>
                                Actualización de 'Régimen fiscal'
                            </React.Fragment> : <React.Fragment>
                                Nuevo contacto de facturación
                            </React.Fragment>
                        }
                    </ModalHeader>
                    <ModalBody>
                        {
                            invoiceId != null ? <React.Fragment>
                                Hemos detectado que su contacto de facturación no cuenta con un régimen fiscal actualizado.
                                <br/>
                                <br/>
                                El contacto de facturación activo se cargará a continuación y podrás editarlo. <b>Todos los campos son requeridos.</b>                            <br/>
                                <br/>
                                <br/>
                                <b>Una vez terminado este proceso, podrás comprar documentos.</b>
                            </React.Fragment> : <React.Fragment>
                                Hemos detectado que no cuentas con un contacto de facturación. <b>Todos los campos son requeridos.</b>                                <br/>
                                <br/>
                                <b>Una vez terminado este proceso, podrás comprar documentos.</b>
                            </React.Fragment>
                        }
                        <br/>
                        <br/>
                        <FormGroup>
                        <Label for="name">Razón social</Label>
                            <Input type="text" id="name" value={data.name} onChange={handleTextChange('name')} required/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="rfc">RFC</Label>
                            <Input type="text" id="rfc" value={data.rfc} onChange={handleTextChange('rfc')} required/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="nombreFacturacion">Nombre</Label>
                            <Input type="text" id="nombreFacturacion" value={data.contactName} onChange={handleTextChange('contactName')} required/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="email">Correo electrónico</Label>
                            <Input type="email" id="emailFacturacion" value={data.contactEmail} onChange={handleTextChange('contactEmail')} required/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="telefonoFacturacion">Telefono</Label>
                            <Input type="number" id="telefonoFacturacion" value={data.contactPhone} onChange={handleTextChange('contactPhone')} required/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="postalCode">Código postal</Label>
                            <Input type="text" id="postalCode" value={data.postalCode} onChange={handleTextChange('postalCode')} required/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="CFDI">Uso del CFDI</Label>
                            <Input id="cfdi" type="select" value={data.cfdiId} onChange={handleTextChange('cfdiId')} required>
                                <option value=""></option>
                                {cfdiSelect}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="regimenFiscal">Régimen fiscal</Label>
                            <Input disabled={working} id="regimenFiscal" type="select" value={data.regimenFiscal.code} onChange={handleTextChange('regimenFiscal')} required>
                                <option value=""></option>
                                {regimenFiscalesSelect}
                            </Input>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" disabled={working} color="primary">Guardar</Button>
                    </ModalFooter>
                </Form>
            </Modal>
    );
}
export default UpdateRegimenFiscal;
