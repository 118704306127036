import React, {useState, useEffect, useRef} from 'react'
import {httpGet, httpPost} from '../shared/Config';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter,
    Form, FormGroup, Label, Input, FormText} from 'reactstrap';
import {useTranslation} from "react-i18next";


function UserTokenModal({isOpen, onCancel, onDeleted, user, orgId}) {

    const modalRef = useRef(null);
    const [working, setWorking] = useState(false);
    const {t} = useTranslation()

    useEffect(() => {
        if(!modalRef.current) {
            return;
        }
        if(isOpen) {
            modalRef.current.showModal();
        } else {
            modalRef.current.close();
        }
    }, [isOpen])

    function handleCancel() {
        onCancel();
    }

    async function handleOk() {
        setWorking(true);
        try {
            const response = await httpPost(`/orgs/${orgId}/users/remove`, {
                userId: user.id
            });
            setWorking(false);
            if(response.data && response.data.success) {
                onDeleted(user);
            }
        } catch(e) {
            console.error(e);
            setWorking(false);
        }
    }

    return (
        <dialog ref={modalRef} className={'modal'}>
            <div className={'prose modal-box'}>
                {
                    isOpen &&
                    <>
                        <h3>
                            {t('DELETE USER')}
                        </h3>
                        <div>
                            <p>
                                {t('DELETE USER MESSAGE', {
                                    userData: user.certData
                                })}
                            </p>
                            <p>
                                {t('DELETE USER MESSAGE CONFIRMATION')}
                            </p>
                        </div>
                        <div className={'modal-action'}>
                            <button
                                className={'btn btn-primary'}
                                disabled={working} color="primary" onClick={handleOk}>
                                <span>{t('OK')}</span>
                            </button>
                            <button
                                className={'btn'}
                                disabled={working} color="secondary" onClick={handleCancel}>
                                <span>{t('CANCEL')}</span>
                            </button>
                        </div>
                    </>
                }
            </div>
        </dialog>
    )
}

export default UserTokenModal;
