import React, { useState } from 'react';
import Section from '../shared/Section'
import SelectGlobalInvoiceData from '../shared/SelectGlobalInvoiceData';
import SelectGlobalSupportData from '../shared/SelectGlobalSupportData';

import { useTranslation } from 'react-i18next';

function InvoiceDataList() {
    const {t} = useTranslation();
    const [invoiceData, setInvoiceData] = useState({
        working: false
    })
    const [supportData, setSupportData] = useState({
        working: false
    })


    return (
        <div>
            <div className={'prose'}>
                <h3>{t('BILLING_SUPPORT')}</h3>
                <p>
                    {t('BILLING_SUPPORT_DESC')}
                </p>
                <p>
                    {t('BILLING_SUPPORT_BILLING_EMAIL_DESC')}
                </p>
                <p>
                    {t('BILLING_SUPPORT_SUPPORT_EMAIL_DESC')}
                </p>
            </div>
            <div className="row">
                <div className="col">
                    <Section>
                        <div className="col">
                            <SelectGlobalInvoiceData
                                disabled={invoiceData.working}/>
                        </div>
                    </Section>
                    <Section>
                        <div className="col">
                            <SelectGlobalSupportData
                                disabled={supportData.working}/>
                        </div>
                    </Section>
                </div>
            </div>
        </div>

    )
}

export default InvoiceDataList;
