import React, {useState, useEffect} from 'react'
import axios from 'axios';
import {backend} from '../shared/Config';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter,
    Form, FormGroup, Label, Input} from 'reactstrap'

import { useTranslation } from 'react-i18next';
import SimpleModal from "../shared/SimpleModal";
import InputText from "../shared/InputText";

const defaultData = {
    supportName: '',
    supportEmail: '',
    supportPhone: ''
}

function SupportDataModal({isOpen, initialState, onCancel, onSaved}) {
    const {t} = useTranslation();
    const [iData, setIData] = useState(defaultData)
    const [working, setWorking] = useState(false);

    useEffect(() => {
        setIData(initialState || defaultData);
    }, [isOpen, initialState])

    function handleCancel() {
        onCancel();
    }

    function handleTextChange(attr) {
        return function(event) {
            setIData({...iData, [attr]:event.target.value})
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        setWorking(true);
        try {
            const response = await axios.post(backend('/supportData'), iData);
            setWorking(false);
            onSaved(response.data);
        } catch(e) {
            setWorking(false);
        }
    }

    return (
        <SimpleModal isOpen={isOpen}>
            <form onSubmit={handleSubmit}>
                <h3>
                    {t('SUPPORT_CONTACT_INFORMATION')}
                </h3>
                <div>
                    <InputText
                        value={iData.supportName}
                        onChange={handleTextChange('supportName')}
                        required>
                        {t('NAME')}
                    </InputText>
                    <InputText
                        value={iData.supportEmail}
                        onChange={handleTextChange('supportEmail')}
                        required>
                        {t('EMAIL')}
                    </InputText>
                    <InputText
                        type="number"
                        value={iData.supportPhone}
                        onChange={handleTextChange('supportPhone')}
                        required>
                        {t('PHONE')}
                    </InputText>
                </div>
                <div className={'modal-action'}>
                    <Button type="submit" disabled={working} color="primary">{t('SAVE')}</Button>
                    <Button disabled={working} color="secondary" onClick={handleCancel}>{t('CANCEL')}</Button>
                </div>
            </form>
        </SimpleModal>
    )
}

export default SupportDataModal;
