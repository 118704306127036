import React from 'react'
import {Button, Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import SimpleModal from "./SimpleModal";

function ConfirmationModal({isOpen, onCancel, onOk, title, children, processing}) {

    const {t} = useTranslation();

    return (
        <SimpleModal isOpen={isOpen}>
            <h3>
                {t(title)}
            </h3>
            <div>
                {children}
            </div>
            <div className={'modal-action'}>
                <button className={'btn btn-primary'} disabled={processing} color="primary" onClick={onOk}>{t('OK')}</button>
                <button className={'btn'
                } disabled={processing} color="secondary" onClick={onCancel}>{t('CANCEL')}</button>
            </div>
        </SimpleModal>
    )
}

export default ConfirmationModal;
