import React from 'react';

function Section({children, className, ...rest}) {
    return (
        <div className={`Section ${className ?? ''}`} {...rest}>
            <div className="container p-2">
                {children}
            </div>
        </div>
    )
}

export default Section
