import React, {useEffect, useRef, useState} from "react";
import {httpPost} from "../shared/Config";
import MoneyFormat from "../shared/MoneyFormat";
import SelectPaymentType from "../payments/SelectPaymentType";
import ConfirmationModal from "../shared/ConfirmationModal";
import EmailNoticeSPEI from "../payments/EmailNoticeSPEI";
import {useTranslation} from "react-i18next";

const debounce = (mainFunction, delay) => {
    // Declare a variable called 'timer' to store the timer ID
    let timer;

    // Return an anonymous function that takes in any number of arguments
    return function (...args) {
        // Clear the previous timer to prevent the execution of 'mainFunction'
        clearTimeout(timer);

        // Set a new timer that will execute 'mainFunction' after the specified delay
        timer = setTimeout(() => {
            mainFunction(...args);
        }, delay);
    };
};

const calcTotal = debounce(async function (value, callback) {
    const res = await httpPost('/calculateTotalWhatsAppMessages', value)
    callback(res.data)
}, 1000)

function BuyGlobalWhatsAppMessages({isOpen, onCancel, tenant, onSaved}) {

    const modalRef = useRef(null);
    const {t} = useTranslation();

    const [paymentType, setPaymentType] = useState('SPEI');
    const [amountSelected, setAmountSelected] = useState(100);
    const [prices, setPrices] = useState({});
    const [working, setWorking] = useState(false);
    const [showSpeiModal, setShowSpeiModal] = useState(false);
    const [noticeModal, setNoticeModal] = useState(false);
    const [error, setError] = useState(null);


    useEffect(() => {
        if(!modalRef.current) {
            return;
        }
        if(isOpen) {
            modalRef.current.showModal();
        } else {
            modalRef.current.close();
        }
    }, [isOpen])

    async function handlePaySubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        if(amountSelected < 100) {
            return;
        }
        if (paymentType === 'Stripe0') {
            const response = await httpPost(`/payments/whatsapp/intentStripePayment`, {
                amount: amountSelected,
                paymentType
            })
            if (response.data.error) {
                setError(response.data.error);
            } else if (response.data.checkoutUrl) {
                window.location.href = response.data.checkoutUrl;
            }
        } else {
            setShowSpeiModal(true);
        }
    }

    async function handleSpeiSubmit() {
        setWorking(true);
        setShowSpeiModal(false);
        try {
            const res = await httpPost(`/orgs/globalWhatsAppMessages`, {
                amount: amountSelected,
                paymentType
            })
            const {success, error} = res.data;
            if(success) {
                setNoticeModal(true);
            } else {
                console.log(error);
            }
        } catch(e) {

        }
        setWorking(false)
    }

    function handleSpeiCancel() {
        setShowSpeiModal(false);
    }

    function handleCancel() {
        onCancel();
    }

    function handleAmountChange(paymentType, amount) {
        setWorking(true)
        calcTotal({
            paymentType,
            amount
        }, r => {
            if (r.success) {
                setPrices(r.data);
            }
            setWorking(false)
        });
    }

    function handlePaymentTypeChange(type) {
        setPaymentType(type.name);
        handleAmountChange(type.name, amountSelected);
    }

    return (
        <dialog ref={modalRef} className={'modal'}>
            <div className={'prose modal-box'}>
                {
                    isOpen &&
                    <form onSubmit={handlePaySubmit}>
                        <h3>
                            {t('PURCHASE WHATSAPP MESSAGES')}
                        </h3>
                            <div>
                                <div style={{marginBottom: '2%'}}>
                                    {t('ALL_PRICES_INCLUDE_TAX_AND_EXPIRE', {
                                        months: '15',
                                        buyType: t('mensajes')
                                    })}
                                </div>
                            </div>
                        <label className="form-control w-full ">
                            <div className="label">
                                <span className="label-text">
                                    {t('MINIMUM MESSAGES')}
                                </span>
                            </div>
                            <input type="number"
                                   className="input input-bordered w-full "
                                   min={100}
                                   value={amountSelected.toString()}
                                   onChange={e => {
                                       let number = Math.max(parseInt(e.target.value),0);
                                       setAmountSelected(number)
                                       handleAmountChange(paymentType, number);
                                   }} required/>
                        </label>
                            {
                                working ?
                                    <div className="d-flex justify-content-center">
                                        <span className="loading loading-spinner loading-xs"></span>
                                    </div> : null
                            }
                            {t('UNIT_PRICE')} <MoneyFormat>{prices.precioUnitarioIva}</MoneyFormat> {tenant.currency}
                            <div>
                                <SelectPaymentType value={paymentType} action="CONSUMABLE"
                                                   type="mensajes"
                                                   onChange={handlePaymentTypeChange}
                                                   pendingPayments={false}
                                                   askFacturacion={false}
                                                   speiDiscount={false}
                                                   paymentMethods={tenant.paymentMethods}/>
                            </div>
                            <div>
                                {t('TOTAL_PRICE')} <MoneyFormat>{prices.totalIva}</MoneyFormat> {tenant.currency}
                            </div>
                        {
                            error ? <React.Fragment>
                                <span style={{fontWeight: 'bold', color: 'red'}}>{error}</span>
                                <br/>
                                <br/>
                            </React.Fragment> : null
                        }
                        <ConfirmationModal
                            isOpen={showSpeiModal}
                            onOk={handleSpeiSubmit}
                            onCancel={handleSpeiCancel}
                            title={'Pago por SPEI'}
                        >
                            <p>
                                {t('PURCHASE_CONFIRMATION1')} <b>{amountSelected} {t('WHATSAPP_MESSAGES')} </b> {t('USING')} <b>SPEI</b>. 
                                {t('PURCHASE_CONFIRMATION2')} <b>'{t('BUY')}'</b> {t('PURCHASE_CONFIRMATION3')} {t('MESSAGES')} {t('PURCHASE_CONFIRMATION4')} <b>SPEI</b>
                            </p>
                            <p>¿Deseas continuar?</p>
                        </ConfirmationModal>
                        <EmailNoticeSPEI
                            isOpen={noticeModal}
                            onClose={() => {
                                setShowSpeiModal(false)
                                setNoticeModal(false)
                                onSaved()
                            }}
                        ></EmailNoticeSPEI>
                        <div className={'modal-action'}>
                            <button
                                className={'btn btn-primary'}
                                type="submit" disabled={working || amountSelected < 100} color="primary">
                                {t('PAY')}
                            </button>
                            <button
                                className={'btn'}
                                disabled={working} color="secondary" onClick={handleCancel}>
                                {t('CANCEL')}
                            </button>
                        </div>
                    </form>
                }
            </div>

        </dialog>
    )

}

export default BuyGlobalWhatsAppMessages;
