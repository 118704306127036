import React, {useState, useEffect} from 'react';
import Section from '../shared/Section';
import Table from '../shared/Table';
import {httpGet} from '../shared/Config';
import MoneyFormat from '../shared/MoneyFormat'
import DateFormat from '../shared/DateFormat'
import moment from 'moment';
import { Collapse, Button, Card } from 'reactstrap';
import {useTranslation} from "react-i18next";
//import {Elements} from '@stripe/react-stripe-js';
//import {loadStripe} from '@stripe/stripe-js';

//const stripeObject = loadStripe(process.env.REACT_APP_HOST);

function PaymentsListSection({status, title, desc, hideIfNoData, typeString}) {
    const [payments, setPayments] = useState([]);
    const [noPayments, setNoPayments] = useState('');
    const {t} = useTranslation();

    useEffect(() => {
        async function fetchPayments() {
            const response = await httpGet(`/payments/${status}`);
            setPayments(response.data);
            if(response.data.length === 0) {
                setNoPayments(t('No pending type transactions', {
                    type: typeString
                }))
            }
        }

        fetchPayments();
    }, [status]);

    useEffect(() => {
        console.log("Hola" + payments.length)
    }, [payments]);

    /*function payItem(event, index) {
        setPaymentModal({...paymentModal, open: true, activePayment: index});
    }

    function modalClose() {
        setPaymentModal({...paymentModal, open: false, activePayment: null});
    }

    function stripeApproved() {
        setPaymentModal({...paymentModal, open: false}); // message confirmation
    }*/

    function PaymentsTable() {


        const {t} = useTranslation();

        if(hideIfNoData && payments.length === 0) {
            return (
                <Section>
                    <p>{noPayments}</p>
                </Section>
            );
        }
        return (
            <Section className={''}>
                <h4>{title}</h4>
                {desc}
                <br/>
                {/*<StripeModal
                    isOpen={paymentModal.open}
                    paymentId={paymentModal.activePayment}
                    onCancel={modalClose}
                onStripeApproved={stripeApproved} />*/}
                <p>{noPayments}</p>
                <div className={'overflow-auto'}>
                    <Table collection={payments} noData="Sin información">
                        <thead>
                        <tr>
                            <th></th>
                            <th>{t('Concept')}</th>
                            <th>{t('Detail')}</th>
                            <th>{t('Amount')}</th>
                            <th>{t('Reference')}</th>
                            <th>{t('Date')}</th>
                            <th>{t('Payment in installments')}</th>
                            {
                                status === 'PENDING_SPEI' ?
                                    <th>{t('Due date')}</th> : null
                            }
                            {/*<th>Pagar</th>*/}
                        </tr>
                        </thead>
                        <tbody>
                        {
                            payments.map((p,i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>
                                        {
                                            p.masterOrganization ? <React.Fragment>
                                                {
                                                    p.buyType ? <React.Fragment>
                                                        {
                                                            p.buyType === "WHATSAPP" ? <React.Fragment>{t('WHATSAPP_PURCHASE')}</React.Fragment> : null
                                                        }
                                                        {
                                                            p.buyType === "IDENTITY_VERIFICATIONS" ? <React.Fragment>{t('VERIFICATIONS_PURCHASE')}</React.Fragment> : null
                                                        }
                                                        {
                                                            p.buyType === "DOCUMENTS_AND_TIMESTAMPS" || p.buyType === "DOCUMENTS" ? <React.Fragment>{t('DOCUMENTS_PURCHASE')}</React.Fragment> : null
                                                        }
                                                        {
                                                            p.buyType === "TIMESTAMPS" ? <React.Fragment>
                                                                {t('TIMESTAMPS_PURCHASE')}
                                                            </React.Fragment> : null
                                                        }
                                                        {
                                                            p.buyType === "EXTERNAL_STORAGE_SUBSCRIPTION" ? <React.Fragment>
                                                                {t('SUSCRIPTION_CONCEPT')}
                                                            </React.Fragment> : null
                                                        }
                                                    </React.Fragment> : <React.Fragment>
                                                        {t('NOT_RELATED_PURCHASE')}
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>  : <React.Fragment>
                                                {p.organizationName}
                                            </React.Fragment>
                                        }
                                    </td>
                                    <td>
                                        {
                                            p.buyType === "DOCUMENTS_AND_TIMESTAMPS" || p.buyType === "DOCUMENTS" ? <React.Fragment>{p.buyAmount} {t('DOCUMENTS')} {t('VIA')} {p.paymentType}</React.Fragment> :
                                                <React.Fragment>
                                                    {
                                                        p.buyType === "TIMESTAMPS" ? <React.Fragment>{p.buyAmount} {t('TIMESTAMPS')} {t('VIA')} {p.paymentType}</React.Fragment> :
                                                            <React.Fragment>
                                                                {p.concept} {t('VIA')} {p.paymentType}
                                                            </React.Fragment>
                                                    }
                                                </React.Fragment>
                                        }
                                    </td>
                                    <td>
                                        <MoneyFormat>{p.amount}</MoneyFormat>
                                        {
                                            p.monthlyPay ? <React.Fragment> / <MoneyFormat>{p.monthlyAmount}</MoneyFormat> ({t('FOR')} {p.months} {t('MONTHS')}) </React.Fragment> : null
                                        }

                                        {/*
                                            p.monthlyPay ? <React.Fragment>
                                                <MoneyFormat>{p.amount}</MoneyFormat>
                                            </React.Fragment> : <React.Fragment>
                                                {
                                                    p.buyType === "CORPORATIVO_SINGLE_PAY" ? <MoneyFormat>{p.amount}</MoneyFormat> :
                                                    <MoneyFormat>{p.amountSinglePay}</MoneyFormat>
                                                }
                                            </React.Fragment>
                                        */}
                                        {/*
                                            p.monthlyPay && p.buyType !== "PERSONAL_SERVICE_PAY" ? <React.Fragment> / <MoneyFormat>{p.amount/12}</MoneyFormat> (mes)</React.Fragment> : null
                                        */}
                                    </td>
                                    <td>
                                        {p.reference}
                                    </td>
                                    <td>
                                        <DateFormat>
                                            {p.createdAt}
                                        </DateFormat>
                                    </td>
                                    <td>
                                        {
                                            p.monthlyPay && p.buyType !== "PERSONAL_SERVICE_PAY" ? <React.Fragment>{t('YES')} </React.Fragment> : <React.Fragment>{t('NO')} </React.Fragment>
                                        }
                                    </td>
                                    {
                                        status === 'PENDING_SPEI' ?
                                            <td>
                                                <DateFormat>{moment(p.createdAt).add(3,'days')}</DateFormat>
                                            </td> : null
                                    }
                                    {/*<td>
                                        <Button color="link" onClick={(event) =>payItem(event, p.id)} style={{ marginBottom: '1rem' }}>Pagar</Button>
                                    </td>*/}
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                </div>


            </Section>
        )
    }

    return (
        <PaymentsTable/>
    )

}

export default PaymentsListSection;
