import React, {useEffect, useState} from 'react';
import {Button, Form, Label, Input, FormGroup} from 'reactstrap';
import axios from 'axios';
import {httpGet, backend} from '../shared/Config';
import InputText from "./InputText";
import InputSelect from "./InputSelect";

const defaultData = {
    name: '',
    rfc: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    postalCode: '',
    cfdiId: '',
    regimenFiscal: ''
}

function UpdateRegimenFiscalInline({isOpen, invoiceId, onSaved, askFacturacion}) {

    const [data, setData] = useState(defaultData);
    const [working, setWorking] = useState(false);

    function handleTextChange(attr) {
        return function (event) {
            setData({...data, [attr]: event.target.value})
        }
    }

    const [regimenFiscalesSelect, setRegimenFiscalesSelect] = useState();
    const [cfdiSelect, setCfdiSelect] = useState();

    useEffect(() => {
        async function fetchRegimenFiscales() {
            try {
                const response = await httpGet(`/getCFDIs`);
                setCfdiSelect(response.data.map((cfdiId) => <option key={cfdiId.id}
                                                                    value={cfdiId.code}>{cfdiId.desc}</option>));
            } catch (e) {
            }

        }

        fetchRegimenFiscales();
    }, [isOpen])

    useEffect(() => {
        async function fetchRegimenFiscales() {
            try {
                const response = await httpGet(`/getRegimenFiscales/${data.cfdiId}`);
                setRegimenFiscalesSelect(response.data.map((regimenFiscal) => <option key={regimenFiscal.id}
                                                                                      value={regimenFiscal.code}>{regimenFiscal.data}</option>));
            } catch (e) {
            }
        }

        if (data.cfdiId) {
            fetchRegimenFiscales();
        }
    }, [data.cfdiId])


    useEffect(() => {
        setWorking(true);
        if (invoiceId && invoiceId !== 0) {
            async function fetchInvoice() {
                const response = await httpGet(`/invoiceData/${invoiceId}`);
                if (response.data) {
                    setData({
                        ...response.data,
                        name: response.data.name,
                        rfc: response.data.rfc,
                        email: response.data.email,
                        postalCode: response.data.postalCode,
                        cfdi: response.data.cfdi,
                        regimenFiscal: ''
                    });
                }
                setWorking(false);
            }

            fetchInvoice();
        } else {
            setWorking(false);
        }
    }, [invoiceId]);

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        setWorking(true);
        try {
            const response = await axios.post(backend('/invoiceData'), data);
            setWorking(false);
            onSaved(response.data);
        } catch (e) {
            setWorking(false);
        }
    }

    return (
        <form onSubmit={handleSubmit} className={'flex-col flex  prose'}>
            <h3>Contrata un paquete de documentos</h3>
                Por favor llena la siguiente información, <b>todos los campos son obligatorios</b>
                <div>
                    <h4>Información de la cuenta</h4>
                    <InputText
                        value={data.companyName}
                        onChange={handleTextChange('companyName')} required>
                        Nombre de la cuenta
                    </InputText>
                    <InputText
                        value={data.contactName}
                        onChange={handleTextChange('contactName')} required>
                        Nombre personal
                    </InputText>
                    <InputText
                        type="email"
                        value={data.contactEmail}
                        onChange={handleTextChange('contactEmail')} required>
                        Correo electrónico
                    </InputText>
                    <InputText
                        type="number"
                        value={data.contactPhone}
                        onChange={handleTextChange('contactPhone')} required>
                        Telefono
                    </InputText>
                    <hr/>
                    <h4>Información de facturación</h4>
                    <InputText
                        value={data.name}
                        onChange={handleTextChange('name')}
                        required>
                        Razón social
                    </InputText>
                </div>
            <InputText
                value={data.rfc}
                onChange={handleTextChange('rfc')}
                required>
                RFC
            </InputText>
            <InputText
                value={data.postalCode}
                onChange={handleTextChange('postalCode')}
                required>
                Código postal
            </InputText>
            <InputSelect label={'Uso del CFDI'}
                         value={data.cfdiId}
                         onChange={handleTextChange('cfdiId')}
                         required>
                <option value=""></option>
                {cfdiSelect}
            </InputSelect>
            <InputSelect label={'Régimen fiscal'}
                         disabled={working}
                         value={data.regimenFiscal.code}
                         onChange={handleTextChange('regimenFiscal')}
                         required>
                <option value=""></option>
                {regimenFiscalesSelect}
            </InputSelect>
                <br/>
            <button className={'btn btn-primary'} type="submit" disabled={working} color="primary">Guardar</button>
        </form>
    );
}

export default UpdateRegimenFiscalInline;
