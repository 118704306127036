import React, {useState, useEffect} from 'react';
import PaymentsListSection from './PaymentsListSection';
import SpeiInfoModal from '../shared/SpeiInfoModal';
import PackagesBoughtInfoListSection from './PackagesBoughtInfoListSection';
import {useTranslation} from "react-i18next";

function Payments() {

    const [showSpeiInfo, setShowSpeiInfo] = useState(false)
    const {t} = useTranslation();

    return (
        <div>
            <div className={'prose'}>
                <h3>
                    {t('PAYMENT_INFO')}
                </h3>
            </div>
            <br/>
            <PaymentsListSection
                hideIfNoData={true}
                title={t('Pending transfers (SPEI)')}
                desc={
                <div className={'prose'}>
                    <p>
                        {t('Please make the transfer before the deadline to continue using the service')}
                    </p>
                    <button className={'btn btn-outline ml-5'}  onClick={() => setShowSpeiInfo(true)}>
                        {t('More information')}
                    </button>
                </div>
                }
                status="PENDING_SPEI"
                typeString="'Pendientes de SPEI'" />
            <PaymentsListSection
                hideIfNoData={true}
                title={t('Completed')}
                status="COMPLETED"
                typeString="'Completadas'"/>
            <SpeiInfoModal
                isOpen={showSpeiInfo}
                onClose={() => setShowSpeiInfo(false)}></SpeiInfoModal>
            <PackagesBoughtInfoListSection
                hideIfNoData={true}
                title={t('Bought packs')}
                desc={
                    <div className={'prose'}>
                        <p>
                            {t('Bought packs description')}
                        </p>
                        <p>
                            {t('Bought packs expired description')}
                        </p>
                    </div>
                }
                status="Todos"
                typeString="todos" />
        </div>
    )
}

export default Payments;
