import React, {useState, useEffect} from 'react'
import {httpGet, httpPost} from '../shared/Config';
import {FormGroup, Label, Input, Button} from 'reactstrap';
import SupportDataModal from '../supportData/SupportDataModal';

import { useTranslation } from 'react-i18next';
import InputSelect from "./InputSelect";

function SelectGlobalSupportData({disabled}) {
    const {t} = useTranslation();
    const [supportDataList, setSupportDataList] = useState([]);
    const [supportModal, setSupportModal] = useState({
        open: false,
        initialState: null
    });
    const [valueSelected, setValueSelected] = useState();
    const [supportInfo, setSupportInfo] = useState({
        id: '',
        email: ''
    })

    const [activeSupportInfo, setActiveSupportInfo] = useState({
        id: ''
    })
    const [working, setWorking] = useState(false);

    const [supportChecked, setSupportChecked] = useState(false);
    const [noSupport, setNoSupport] = useState(true);
    const [autoSet, setAutoSet] = useState(false);
    const [initialCheck, setInitialCheck] = useState(false);
    const [noSupportList, setNoSupportList] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchSupportDataList(true);
    }, [])

    async function fetchSupportDataList(globalFetch) {
        setNoSupport(false);
        setWorking(true);
        try {
            const response = await httpGet(`/supportData/select`);
            if(response.data){
                setSupportDataList(response.data);

                if(response.data.length == 0){
                    setNoSupport(true);
                } else {
                    setAutoSet(true);
                }

                if(globalFetch){
                    const currentValue = await httpGet(`/supportData/getGlobalSupport`);
                    if(currentValue.data && currentValue.data.id != null) {
                        setInitialCheck(true);
                        //setValueSelected(currentValue.data.id);
                        setSupportInfo(currentValue.data);
                        setActiveSupportInfo(currentValue.data)
                        setNoSupport(false);
                    } else {
                        if(response.data.length > 0){
                            await httpPost(`/supportData/updateSupportData`, {
                                supportContactId: response.data[0].id
                            });
                            const responseDef = await httpGet(`/supportData/${response.data[0].id}`);
                            setSupportInfo({...responseDef.data, working: false});
                            setValueSelected(response.data[0].id);
                        }
                        /*setNoSupport(true);

                        if(response.data.length > 0){
                            const responseDef = await httpGet(`/supportData/${response.data[0].id}`);
                            setSupportInfo({...responseDef.data, working: false});
                            setValueSelected(response.data[0].id);
                        }*/
                    }
                }
                //setSupportChecked(true);
            }
        } catch(e) {
            console.error(e);
            setError('COULND_FETCH_SUPPORT_DATA');
            setSupportDataList([]);
            setWorking(false);
        }
        setWorking(false);
    }

    function handleSelectChange(event) {
        setWorking(true);
        setValueSelected(event.target.value);
    }

    useEffect(() => {
        if(valueSelected) {
            async function updateSupport(){
            await httpPost(`/supportData/updateSupportData`, {
                    supportContactId: valueSelected
                });
                const responseDef = await httpGet(`/supportData/${valueSelected}`);
                setSupportInfo({...responseDef.data, working: false});
                setActiveSupportInfo(responseDef.data);
                setWorking(false);
            };

            updateSupport();
        }
    }, [valueSelected])

    /*function handleSelectChange(event) {
        setValueSelected(event.target.value);
        //onChange(event.target.value);
        async function fetchSupportData() {
            const response = await httpGet(`/supportData/${event.target.value}`);
                setSupportInfo({...response.data, working: false});
            }
        fetchSupportData();
    }*/

    function handleSupportDataSaved(dataSaved) {
        setWorking(true);
        setValueSelected(dataSaved.id);
        setSupportModal({initialState: null, open: false});
        window.location.reload();
    }

    /*function handleSupportDataSaved(dataSaved) {
        setSupportModal({...supportModal, open:false})
        fetchSupportDataList(false);
        setValueSelected(dataSaved.id);
        setSupportInfo(dataSaved);
        if(dataSaved.id === activeSupportInfo.id) {
            setActiveSupportInfo(dataSaved);
        }
        if(autoSet) {
            async function updateSupport(){
                await httpPost(`/supportData/updateSupportData`, {
                    supportContactId: dataSaved.id
                });
                setActiveSupportInfo(dataSaved);
            };
            updateSupport();
            setAutoSet(false);
        }
        setNoSupport(false);
    }*/

    function handleEdit(iData) {
        return function() {
            setSupportModal({initialState: iData, open: true})
        }
    }

    function handleUpdateSupport() {
        async function updateSupport(){
            await httpPost(`/supportData/updateSupportData`, {
                supportContactId: valueSelected
            });
            setNoSupport(false);
            setActiveSupportInfo(supportInfo);
        };
        updateSupport();
    }

    function handleAddSupport() {
        setSupportModal({initialState: null, open: true})
    }

    return (
        <React.Fragment>
            <b>{t('SUPPORT_CONTACT')}</b>
            <br/>
            <br/>
            {
                supportDataList.length > 0 ? <React.Fragment>
                    {

                        noSupport ? <React.Fragment>
                                <span style={{color: 'red', fontWeight:'bold', marginBottom: '10vh'}}>{t(error)}</span>
                            </React.Fragment> : <React.Fragment>
                                <b>{t('ACTIVE_SUPPORT_CONTACT')}</b>
                                <br/>
                                <br/>
                                <p>
                                    {t('NAME')}: {activeSupportInfo.supportName} <br/>
                                    {t('EMAIL')}: {activeSupportInfo.supportEmail} <br/>
                                    {t('PHONE')}: {activeSupportInfo.supportPhone} <br/>
                                </p>
                            </React.Fragment>
                        }
                        <br/>
                        <div className="row justify-content-end">
                            <button className={'btn btn-secondary'} disabled={working} style={{marginRight:'10px'}} color="primary" onClick={handleEdit(supportInfo)}>
                                {t('EDIT_CONTACT')}
                            </button>
                        </div>
                        <br/>
                        <InputSelect
                            value={valueSelected}
                            onChange={handleSelectChange}
                            disabled={working}
                            required>
                            <option selected disabled></option>
                            {
                                supportDataList.map(i => (
                                    <option key={i.id} value={i.id}>{i.supportEmail} - {i.supportName}</option>
                                ))
                            }

                        </InputSelect>
                    </React.Fragment> : <React.Fragment>
                        <br/>
                        <span style={{color: 'red', fontWeight:'bold'}}>{t('NO_SUPPORT_CONTACT')}</span>
                    </React.Fragment>
            }
            <br/>
            <hr/>
            <br/>
            <div className="row justify-content-center">
                <button className={'btn btn-secondary'} disabled={working} color="primary" onClick={handleAddSupport}>
                    {t('Add new support contact')}
                </button>
            </div>
            <br/>
            <SupportDataModal
                isOpen={supportModal.open}
                initialState={supportModal.initialState}
                onCancel={() => setSupportModal({initialState: null, open: false})}
                onSaved={handleSupportDataSaved}/>
        </React.Fragment>
    )

}

export default SelectGlobalSupportData
