import React from 'react';
import Section from './Section'
import {Table as BTable} from 'reactstrap'

import { useTranslation } from 'react-i18next';

function TableSection({title, children, collection, noData}) {

    const {t} = useTranslation();

    return (
        <React.Fragment>
            {
                title ? <h1>{t({title})}</h1> : null
            }
            {
                collection && collection.length > 0 ?
                    <table className={'table table-zebra table-xs md:table-md'}>
                        {children}
                    </table> :
                    <div className="NoData">
                        {noData}
                    </div>
            }
        </React.Fragment>
    )
}

export default TableSection
