import React from 'react'

export default function InputSelect({children, label, ...rest}) {
    return (
        <label className="form-control w-full">
            <div className="label">
                <span className="label-text">{label}</span>
            </div>
            <select className="select select-bordered"
                    {...rest}>
                {children}
            </select>
        </label>
    )
}
