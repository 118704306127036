import React, {useState, useEffect, useRef} from 'react'
import {httpGet, httpPost} from '../shared/Config';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter,
    Form, FormGroup, Label, Input, Text, Spinner,Table} from 'reactstrap';
import SelectPaymentType from '../payments/SelectPaymentType';
import ConfirmationModal from '../shared/ConfirmationModal';
import SimpleModal from "../shared/SimpleModal";

function SubscribeModal({isOpen, onCancel, pendingPayments, askFacturacion, existingSubscription, tenant, onComplete}) {

    const [buyState, setBuyState] = useState({
        subscriptionType: 'sharepoint',
        paymentType: 'SPEI',
        mode: 'monthly'
    });

    const [showConfModal, setShowConfModal] = useState(false);
    const [subTypeFormat, setSubTypeFormat] = useState('Sharepoint');
    const [processingPayment, setProcessingPayment] = useState(false);
    const [working, setWorking] = useState(false);
    const [error, setError] = useState("");

    const [priceInfo, setPriceInfo] = useState({});
    const [priceFetch, setPriceFetch] = useState(false);

    useEffect(() => {
        async function fetchSubscriptionPriceInfo() {
            try {
                const subscritionFetch = await httpGet(`/subscription/EXTERNAL_STORAGE_SUBSCRIPTION/getPriceInfo`);
                if(subscritionFetch.data) {
                    if(subscritionFetch.data) {
                        setPriceInfo(subscritionFetch.data);
                    } else if(subscritionFetch.data.error) {
                        console.log(subscritionFetch.data.error);
                    }
                } else {
                    // no subscription found for this type
                }
                setPriceFetch(true);
            } catch(e) {
                console.log(e);
            }
        }

        fetchSubscriptionPriceInfo();
    }, []);

    function handleClick(attr) {
        return function(event) {
            setBuyState({...buyState, [attr]:event.target.value})
        }
    }

    function subscribeConfirmation(e) {
        e.preventDefault();
        e.stopPropagation();
        setShowConfModal(true);
    }

    function handleCancel() {
        onCancel();
    }

    function handleConfCancel() {
        setShowConfModal(false);
    }

    function handlePurchaseClick() {
        if (showConfModal) {
            setProcessingPayment(true);
        }
    }

    useEffect(() => {
        async function processSpeiPayment() {
            if(buyState.paymentType.includes("Stripe")) {
                const response = await httpPost(`/subscription/intentStripePaySubscription`, {buyState})
                if (response.data.error) {
                    setError(response.data.error);
                } else if(response.data.checkoutUrl) {
                    window.location.href = response.data.checkoutUrl;
                }
            } else {
                try {
                    const response = await httpPost(`/subscription/suscribeExternalStorage`, {
                        buyState
                    })
                    const {success, error} = response.data;
                    if (success) {
                        //setNoticeModal({...noticeModal, open: true});
                    } else {
                        console.log(error);
                    }
                    setWorking(false);
                    setProcessingPayment(false);
                    setShowConfModal(false);
                    onComplete();
                } catch (e) {
                    setWorking(false);
                    setProcessingPayment(false);
                    setShowConfModal(false);
                    onCancel();
                }
            }
        }

        if (processingPayment) {
            processSpeiPayment();
        }
    }, [processingPayment])

    function handlePaymentTypeChange(value) {
        setBuyState({...buyState, paymentType: value});
     }


    return (
        <SimpleModal isOpen={isOpen}>
            <form onSubmit={subscribeConfirmation}>
                <h3>
                    Suscribirse a un servicio
                </h3>
                <div>
                    <div>
                        {
                            priceFetch ? <React.Fragment>
                            <p>
                                Costo mensual por SPEI: ${priceInfo.speiExternalStorageMonthly}
                                <br/>
                                Costo anual por SPEI: ${priceInfo.speiExternalStorageYearly}
                                <br/>

                                Costo mensual por STRIPE: ${priceInfo.stripeExternalStorageMonthly}
                                <br/>
                                Costo anual por STRIPE: ${priceInfo.stripeExternalStorageYearly}
                                <br/>

                                Solo puede tener una suscripcion de este tipo por cuenta.
                                <br/>
                                <br/>
                            </p>
                            </React.Fragment> : <React.Fragment>
                                Cargando precios...
                                <br/>
                                <br/>
                            </React.Fragment>
                        }
                    </div>
                    <div>Seleccione la modalidad de cobro</div>
                    <FormGroup style={{marginLeft: '5%', marginTop: '2%', marginBottom: '1%'}} check>
                        <Input name="radio3" type="radio" value="monthly" checked={"monthly" === buyState.mode} onChange={handleClick('mode')} />{' '}
                        <Label check>
                            Mensual
                        </Label>
                    </FormGroup>
                    <FormGroup style={{marginLeft: '5%', marginTop: '2%', marginBottom: '1%'}} check>
                        <Input name="radio3" type="radio" value="yearly" checked={"yearly" === buyState.mode} onChange={handleClick('mode')} />{' '}
                        <Label check>
                            Anual
                        </Label>
                    </FormGroup>
                    <br/>
                    <div>
                        <SelectPaymentType value={buyState.paymentType} subValue={buyState.sub} action="SUB" type="documentos" onChange={handlePaymentTypeChange} pendingPayments={pendingPayments} askFacturacion={askFacturacion} paymentMethods={tenant.paymentMethods} />
                    </div>
                </div>
                <div className={'modal-action'}>
                    <Button type="submit" color="primary">Pagar</Button>
                    <Button color="secondary" onClick={handleCancel}>Cancel</Button>
                </div>
            </form>
            <ConfirmationModal isOpen={showConfModal}
                onCancel={handleConfCancel}
                onOk={handlePurchaseClick}
                title="Suscripcion">
                    <span>Esta por activar una suscripcion de tipo "External storage" con las siguientes caracteristicas:</span>
                    <br/>
                    <span>Modalidad:</span>
                    <br/> <span style={{ textTransform: 'uppercase', fontWeight: 'bold'}}>{buyState.mode}</span>
                    <br/>
                    <span>Metodo de compra</span>
                    <br/> <span style={{ textTransform: 'uppercase', fontWeight: 'bold'}}>{buyState.paymentType}</span>
                    <br/>
                    <p>¿Deseas continuar?</p>
            </ConfirmationModal>
        </SimpleModal>
    )
}

export default SubscribeModal;
