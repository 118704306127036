import React from 'react'
import {Button, Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap';
import { useTranslation } from 'react-i18next';

function ConfirmReactivateModal({isOpen, onCancel, onOk, title, children, reactivateComplete, reactivateWorking}) {
    const {t} = useTranslation();  
    return (
        <Modal isOpen={isOpen}>
            <ModalHeader>
                {t(title)}  
            </ModalHeader>
            <ModalBody>     
                {children}   
            </ModalBody>
            <ModalFooter>
                <Button disabled={reactivateComplete || reactivateWorking} color="primary" onClick={onOk}>{t('RESTART_SUBSCRIPTION')}</Button>
                <Button disabled={reactivateWorking} color="secondary" onClick={onCancel}>{t('CLOSE')}</Button>
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmReactivateModal;