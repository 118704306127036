import React, {useState, useEffect} from 'react'
import {httpPost} from '../shared/Config';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter,
    Form, FormGroup, Label, Input} from 'reactstrap';
import SelectPlan from '../shared/SelectPlan'
import BuyGlobalDocumentsModal from './BuyGlobalDocumentsModal'
import CuentasCorporativasModal from '../plans/CuentasCorporativasModal';
import { useTranslation } from 'react-i18next';
import SimpleModal from "../shared/SimpleModal";
import InputText from "../shared/InputText";

const defaultData = {
    organizationName: '',
    plan: 'CORPORATIVO',
    paymentType: 'SPEI',
    companyName: '',
    factura: false,
    razonSocial: '',
    rfc: '',
    cp: '',
    email: '',
    cfdi: 'Gastos en general',
    regimenFiscal: ''
}

function OrganizationModal({isOpen, onCancel, onSaved, alreadyExists, companyActive, mode, companyName, companyNameNotUpdated, askFacturacion, clientType, tenant}) {

    const {t} = useTranslation();
    const [corporativoUpgrade, setCorporativoUpgrade] = useState(false);
    const [paymentNeeded, setPaymentNeeded] = useState(true);
    const [titleMessage, setTitleMessage] = useState('');

    const [org, setOrg] = useState(defaultData)
    const [working, setWorking] = useState(false);
    //const [showConfModal, setShowConfModal] = useState(false);

    //const [askFacturacion, setAskFacturacion] = useState(false);
    //const [buttonMessage, setButtonMessage] = useState('Proceder al pago');

    const [corpoPlanCost, setCorpoPlanCost] = useState(0);
    const [personalPlanCost, setPersonalPlanCost] = useState(0);

    const [error, setError] = useState('');    /*const [subModal, setSubModal] = useState({
        open: false
    });*/

    //const [regimenFiscales, setRegimenFiscales] = useState({});

    const [buyState, setBuyState] = useState({
        subType: '',
        newOrgId: null,
        planCost: 0
    });

    const [userEditedAccountName, setUserEditedAccountName] = useState(false);

    function handleCancel() {
        setWorking(false);
        setOrg(defaultData);
        onCancel();
        setError('');
    }


    function handleOrgNameChange(attr) {
        return function(event) {
            if(event.target.value.length < 25) {
                setOrg({...org, [attr]:event.target.value})
            }
        }
    }

    function handleAccountNameChange(attr) {
        return function(event) {
            setUserEditedAccountName(true);
            if(event.target.value.length < 25) {
                setOrg({...org, [attr]:event.target.value})
            }
        }
    }

    function handleRazonSocialChange(attr) {
        return function(event) {
            let value = event.target.value;
            if(!userEditedAccountName) {
                let splitValue = value.split(" ");
                setOrg({...org, companyName:splitValue[0], razonSocial: value});
            } else {
                setOrg({...org, razonSocial: value});
            }
        }
    }

    function handleTextChange(attr) {
        return function(event) {
            setOrg({...org, [attr]:event.target.value})
        }
    }

    const [globalDocumentsModal, setGlobalDocumentsModal] = useState({
        open: false
    })

    const [cuentasCorporativasModal, setCuentasCorporativasModal] = useState({
        open: false
    })

    function handleGlobalDocumentsModalCancel() {
        setGlobalDocumentsModal({open:false})
        setWorking(false);
    }

    function handleGlobalDocumentsAdded() {
        setGlobalDocumentsModal({open:false});
        //handleUpgrade();
        onSaved();
    }

    function handleCuentasCorporativasClick() {
        setCuentasCorporativasModal({open:true});
    }

    function handleCuentasCorporativasClose() {
        setCuentasCorporativasModal({open:false});
    }


    function handlePlanChange(plan) {
        setOrg({...org, plan})

        if(corporativoUpgrade) {
            setPaymentNeeded(true);
        } else {
            setPaymentNeeded(false);
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        setError('');

        setWorking(true);

        if(org.plan === "CORPORATIVO" || org.plan === "EMPRESARIAL") {

            if(mode === "PARTIAL" && (!org.organizationName || org.organizationName === '')) {
                return;
            }

            if(paymentNeeded) {
                setBuyState({...buyState, subType: org.plan, planCost: corpoPlanCost});
                async function preSave() {
                    const response = await httpPost('/orgs/saveData/preSaveOrgData', {org});
                    if(response.data.status === "success") {
                        setGlobalDocumentsModal({...globalDocumentsModal, open:true});
                    }
                    setWorking(false);
                }
                preSave();
                //setGlobalDocumentsModal({...globalDocumentsModal, open:true});
            } else {
                const response = await httpPost('/orgs', {org});
                setWorking(false);
                if(response) {
                    if(response.data.data.error) {
                        setError(response.data.data.error);
                    } else {
                        onSaved(response.data.data);
                    }
                }
            }
        }
        setWorking(false);
    }

    useEffect(() => {
        setOrg(orgTemp => {
            const orgValue = orgTemp;
            orgValue.factura = askFacturacion;
            return orgValue;
        });
    }, [askFacturacion]);

    useEffect(() => {
        setCorporativoUpgrade(false);
        switch(mode) {
            case "ALL": {
                setTitleMessage("Contratar servicio");
                setPaymentNeeded(true);
                setCorporativoUpgrade(true);
                break;
            }
            case "PARTIAL" :{
                setTitleMessage("ADD_NEW_DEPARTMENT");
                setPaymentNeeded(false);
                setOrg(orgTemp => {
                    const orgValue = orgTemp;
                    orgValue.plan = 'CORPORATIVO';
                    return orgValue;
                });
                setCorporativoUpgrade(false);
                break;
            }
            case "PARTIAL_PERSONAL": {
                setTitleMessage("CONVERT_AND_ADD_NEW_DEPARTMENT");
                setPaymentNeeded(true);
                setOrg(orgTemp => {
                    const orgValue = orgTemp;
                    orgValue.plan = 'CORPORATIVO';
                    return orgValue;
                });
                setCorporativoUpgrade(true);
                break;
            }
            case "PARTIAL_EMPRESARIAL" :{
                setTitleMessage("ADD_NEW_DEPARTMENT");
                setPaymentNeeded(false);
                setOrg(orgTemp => {
                    const orgValue = orgTemp;
                    orgValue.plan = 'EMPRESARIAL';
                    return orgValue;
                });
                setCorporativoUpgrade(false);
                break;
            }
            case "ONLY_INFO": {
                setTitleMessage("Contratar servicio");
                setPaymentNeeded(false);
                setOrg(orgTemp => {
                    const orgValue = orgTemp;
                    orgValue.plan = 'CORPORATIVO';
                    return orgValue;
                });
                setCorporativoUpgrade(true);
                break;
            }
            default: {
                break;
            }
        }
    }, [mode, isOpen]);


    return (
        <SimpleModal isOpen={isOpen}>
            <form onSubmit={handleSubmit}>
                <h3>
                    {t(titleMessage)}
                </h3>
                <div>
                    {
                        corporativoUpgrade && org.plan === "CORPORATIVO" ? <React.Fragment>
                        {
                            mode === "ONLY_INFO" ? <React.Fragment>
                                {t('ONLY_INFO1')} <b>ON DEMAND</b>. {t('ONLY_INFO2')}
                            </React.Fragment> : null
                        }
                        {
                            mode === "ALL" ? <React.Fragment>
                                {t('DEMO_CONVERT')}
                                <br/>
                                <br/>
                                {t('DEMO_CONVERT_INFO')}
                            </React.Fragment> : null
                        }
                        <br/>
                        <br/>
                        </React.Fragment> : null
                    }
                    {
                        !companyActive ?
                            <p style={{fontWeight: 'bold', color: 'red'}}>{t('COMPANY_DEACTIVATED')}</p>
                         : null
                    }
                    {
                        mode !== "PARTIAL" && mode !== "PARTIAL_EMPRESARIAL" ?
                            <div>
                                <SelectPlan mode={mode} value={org.plan} onChange={handlePlanChange} personalPrice={personalPlanCost}/>
                            </div>
                         : null
                    }
                    {
                        mode === "ALL" ?
                            <div style={{color:'#007bff', cursor: 'pointer', marginBottom: '15px'}}
                            onClick={handleCuentasCorporativasClick}>{t('ON_DEMAND_ACCOUNTS')}</div>
                         : null
                    }
                    {
                        org.plan === "CORPORATIVO" || org.plan === "EMPRESARIAL" ? <React.Fragment>
                            {
                                companyNameNotUpdated ?
                                    <InputText
                                        value={org.companyName}
                                        onChange={handleAccountNameChange('companyName')} required>
                                        {t('ACCOUNT_NAME_INPUT')}
                                    </InputText>
                                : null
                            }
                            {
                                mode === "PARTIAL" || mode === "PARTIAL_EMPRESARIAL" ?
                                    <InputText
                                        value={org.organizationName}
                                        onChange={handleOrgNameChange('organizationName')}
                                        required>
                                        {t('DEPARTMENT_NAME')}
                                    </InputText>
                                 : null
                            }
                        </React.Fragment> : null
                    }
                {
                    error ? <span style={{fontWeight: 'bold', color: 'red'}}>{error}</span> : null
                }
                </div>
                <div className={'modal-action'}>
                    {
                        paymentNeeded ?
                         <Button type="submit" disabled={working || !companyActive} color="primary">{t('GO_BUY_DOCUMENTS')}</Button>
                         : <Button type="submit" disabled={working || !companyActive} color="primary">{t('CONTINUE')}</Button>
                    }
                    <Button disabled={working} color="secondary" onClick={handleCancel}>
                        <span>{t('CANCEL')}</span>
                    </Button>
                </div>
            </form>
            <BuyGlobalDocumentsModal
                    isOpen={globalDocumentsModal.open}
                    onCancel={handleGlobalDocumentsModalCancel}
                    onSaved={handleGlobalDocumentsAdded}
                    companyActive={companyActive}
                    tenant={tenant}
                    type="UPGRADE"
                    org={org}
                />
            <CuentasCorporativasModal
                isOpen={cuentasCorporativasModal.open}
                onClose={handleCuentasCorporativasClose}
                />
        </SimpleModal>
    )
}

export default OrganizationModal;
