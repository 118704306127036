import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StripeSubscriptionCheckout from './StripeSubscriptionCheckout';


import { useTranslation } from 'react-i18next';


const stripeObject = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

function StripeSubscriptionModal({isOpen, buyState, onCancel, onStripeApproved, orgData}) {

    const {t} = useTranslation();  

    const [working, setWorking] = useState(false);
    const [succeeded, setSucceeded] = useState(false);
    const [orgId, setOrgId] = useState(null);

    function handleDismiss(){
        if(succeeded) {
            onStripeApproved(orgId);
        } else {
            onCancel();
        }
    }

    function onSucceeded(newOrgId) {
        setSucceeded(true);
        setOrgId(newOrgId);
    }

    function onWorking() {
        setWorking(true);
    }

    function onProccesEnd() {
        setWorking(false);
    }

    return (
        <React.Fragment>
            <Modal isOpen={isOpen}>
                <ModalHeader>
                    {
                        buyState.subType === "PERSONAL" ? <React.Fragment>{t('SUBSCRIPTION_PAYMENT')}</React.Fragment> : null
                    }
                    {
                        buyState.subType === "CORPORATIVO" ? <React.Fragment>{t('SINGLE_PAYMENT_SPEI')}</React.Fragment> : null
                    }
                </ModalHeader>
                <ModalBody>
                    <Elements stripe={stripeObject}>
                        <StripeSubscriptionCheckout buyState={buyState} onSucceeded={onSucceeded} onWorking={onWorking} onProccesEnd={onProccesEnd} orgData={orgData} planCost={buyState.planCost} />
                    </Elements>
                </ModalBody>
                <ModalFooter>
                    {/* <Button type="submit" disabled={!working} color="primary">Pagar</Button> */}
                    <Button disabled={working} color="secondary" onClick={handleDismiss}>
                        {
                            succeeded ? <React.Fragment>
                                {t('CLOSE')}
                            </React.Fragment> : <React.Fragment>
                                {t('CANCEL')}
                            </React.Fragment>
                        }
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
export default StripeSubscriptionModal;