import React, {useState, useEffect, useRef} from 'react';
import {httpGet} from '../shared/Config';

function EmailNoticeSPEI({isOpen, onClose}) {

    const modalRef = useRef(null);

    const [emailFacturacion, setEmailFacturacion] = useState('');

    useEffect(() => {
        if(!modalRef.current) {
            return;
        }
        if(isOpen) {
            modalRef.current.showModal();
        } else {
            modalRef.current.close();
        }
    }, [isOpen])


    useEffect(function() {
        async function getFacturaEmail() {
            try {
                const response = await httpGet('/invoiceData/getGlobalInvoiceEmail');
                if(response?.data.email) {
                    setEmailFacturacion(response.data.email);
                }
            } catch(e) {
            }
        }

        if(isOpen) {
            getFacturaEmail();
        }

    }, [isOpen])

    return (
        <dialog ref={modalRef} className={'modal'}>
            <div className={'prose modal-box'}>
                {
                    isOpen &&
                    <>
                        <h3>
                            Email enviado
                        </h3>
                            <p>
                                Se ha enviado un correo electronico con instrucciones al correo: <b>{emailFacturacion}</b>,
                                cuenta con tres dias para realizar su pago.
                                <br/>
                            </p>
                            <p>
                                <b>IMPORTANTE:</b> Una vez que haya realizado su pago, envíe un comprobante de transferencia a <b>ventas@firmamex.com</b>.
                                Su pago no se verá reflejado hasta que envíe su comprobante.
                            </p>
                        <div className={'modal-action'}>
                            <button className={'btn btn-primary'} disabled={!emailFacturacion} color="primary" onClick={onClose}>Cerrar</button>
                        </div>
                    </>
                }
            </div>
        </dialog>
    )
}

export default EmailNoticeSPEI
