import React, {useState, useEffect, useCallback} from 'react';
import { httpGet, httpPost } from '../shared/Config';
import {Link} from 'react-router-dom'
import { Button, Alert, Spinner, FormGroup, Label, Input } from 'reactstrap';
import SubscribeModal from './SubscribeModal';
import DateFormat from '../shared/DateFormat'
import moment from 'moment';
import ConfirmationModal from '../shared/ConfirmationModal';
import SectionList from '../shared/SectionList'

import { useTranslation } from 'react-i18next';

function AlternateStorageSubscription() {
    const {t} = useTranslation();
    const [pendingLockingDocumentsPayments, setPendingLockingDocumentsPayments] = useState();   
    const [tenant, setTenant] = useState({});

    const [subscriptionInfo, setSubscriptionInfo] = useState({});
    const [subFetch, setSubFetch] = useState(false);
    const [showCancelConfModal, setShowCancelConfModal] = useState(false);
    const [showReactivateConfModal, setShowReactivateConfModal] = useState(false);

    const [organizations, setOrganizations] = useState(null)
    
    useEffect(() => {
        async function fetchTenant() {
            try {
                const tenant = await httpGet(`/getTenant`);
                let conf = JSON.parse(tenant.data.data.configuration);
                setTenant(conf);
                console.log(conf);

                const subscriptionFetch = await httpGet(`/subscription/EXTERNAL_STORAGE_SUBSCRIPTION/getSubscriptionInfo`);
                if(subscriptionFetch.data) {
                    if(subscriptionFetch.data.subInfo?.id) {
                        setSubscriptionInfo(subscriptionFetch.data.subInfo);

                        if(subscriptionFetch.data.subInfo?.active) {
                            try {
                                const response = await httpGet('/subscription/EXTERNAL_STORAGE_SUBSCRIPTION/getOrganizations');
                                let corporativeFound = 0;
                                for(let plan of response.data) {
                                    if(plan.plan.id === 3 || plan.plan.id === 2) {
                                        corporativeFound++;
                                    }
                                }
                                setOrganizations(response.data);
                            } catch(e) {
                                console.error(e);
                                setOrganizations([]);
                            }
                        }
                    } else if(subscriptionFetch.data.error) {
                        console.log(subscriptionFetch.data.error);
                    }
                } else {
                    // no subscription found for this type
                }
                setSubFetch(true);
            } catch(e) {
                console.log(e);
            }
        }

        fetchTenant();
    }, []);

    /*useEffect(() => {
        if(organizations) {
        for(let org of organizations) {
            fetchConfiguration(org.organization.id);
        }
        }
    }, [organizations]);*/

    const fetchPendingPayments = useCallback(async () => {
        const responseDocuments = await httpGet('/payments/checkIfPendingPayments/DOCUMENTS');
        if(responseDocuments){
            setPendingLockingDocumentsPayments(responseDocuments.data.data.pending);
        }
    },[]);

    const [subscriptionModal, setSubscriptionModal] = useState({
        open: false
    });

    function openSubscriptionModal() {
        setSubscriptionModal({open:true});
    }

    function onCancel() {
        setSubscriptionModal({open:false});
    }

    function startNewSubscriptionPlan() {
        async function startNewSubscriptionPlanFetch() {
            try {
                const subscritionFetch = await httpGet(`/subscription/EXTERNAL_STORAGE_SUBSCRIPTION/cancelSubscription`);
            } catch(e) {
                console.log(e);
            }
        }

        startNewSubscriptionPlanFetch();
    }

    function toggleCancelConfModal() {
        setShowCancelConfModal(!showCancelConfModal);
    }

    
    function cancelCancelConfModal() {
        setShowCancelConfModal(false);
    }

    function confirmCancelConfModal() {       
         async function cancelSubscriptionFetch() {
            try {
                const subscriptionFetch = await httpGet(`/subscription/EXTERNAL_STORAGE_SUBSCRIPTION/cancelSubscription`);

                if(subscriptionFetch) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            } catch(e) {
                console.log(e);
            }
        }

        cancelSubscriptionFetch();
    }

    
    function toggleReactivateConfModal() {
        setShowReactivateConfModal(!showCancelConfModal);
    }

    
    function cancelReactivateConfModal() {
        setShowReactivateConfModal(false);
    }

    function confirmReactivateConfModal() {       
        async function reactiveCurrentSubscriptionFetch() {
            try {
                const subscriptionFetch = await httpGet(`/subscription/EXTERNAL_STORAGE_SUBSCRIPTION/reactivateSubscription`);
                if(subscriptionFetch) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            } catch(e) {
                console.log(e);
            }
        }

        reactiveCurrentSubscriptionFetch();
    }


    function fetchConfiguration(organizationId) {
        async function fetchConfiguration() {
            try {
                const configurationMetaFetch = await httpGet(`/subscription/${organizationId}/getConfiguration`);
                if(configurationMetaFetch) {
                    let g = 5 + 5;
                }
            } catch(e) {
                console.log(e);
            }
        }

        fetchConfiguration();
    }

    function configureAlternateStorage(organizationId) {
        async function configureAlternateStorageFetch() {
            try {
                const configurationFetch = await httpGet(`/subscription/${organizationId}/configureDepartment`);
                if(configurationFetch) {
                    if(configurationFetch.data.urlGrantConsent){
                        window.open(configurationFetch.data.urlGrantConsent, '_blank').focus();
                    }
                }
            } catch(e) {
                console.log(e);
            }
        }

        configureAlternateStorageFetch();
    }

    function onComplete() {
        window.location.reload();
    }

    return (
        <div>
            <div>Suscripción a almacenamiento alterno (Sharepoint/One Drive)</div>
            <br/>
            {
                subFetch ? <React.Fragment>
                    {
                        subscriptionInfo.id ? <React.Fragment>
                            {
                                subscriptionInfo.active ? 
                                <React.Fragment>
                                    ACTIVA                           
                                    <br/>
                                    Fecha de inicio de suscripcion: <DateFormat>{moment(subscriptionInfo.dateSubscriptionStarts)}</DateFormat>
                                    <br/> 
                                    Metodo de pago: {subscriptionInfo.subscriptionPayMethod}
                                    <br/>
                                    {
                                        subscriptionInfo.dateSubscriptionEnds ?  
                                        <React.Fragment>
                                            Fecha de fin de suscripcion: <DateFormat>{moment(subscriptionInfo.dateSubscriptionEnds)}</DateFormat> 
                                            <br/>
                                        </React.Fragment> : null
                                    }
                                </React.Fragment> : 
                                <React.Fragment>
                                    INACTIVA
                                    <br/>
                                    {
                                        subscriptionInfo.status === "CANCELLED" || (subscriptionInfo.subscriptionPayMethod === "STRIPE" && !subscriptionInfo.stripeSubscriptionId) ?
                                        <React.Fragment>
                                        <Button color="primary" onClick={openSubscriptionModal}>Suscribirse</Button>
                                        <br/>
                                        <SubscribeModal 
                                                isOpen={subscriptionModal.open} 
                                                onCancel={onCancel}
                                                pendingPayments={pendingLockingDocumentsPayments} 
                                                askFacturacion={false}
                                                existingSubscription={subscriptionInfo}
                                                tenant={tenant}
                                                onComplete={onComplete}
                                            />    
                                        <br/>
                                        </React.Fragment> : null 
                                    }
                                </React.Fragment>
                            }
                            <br/>
                            <br/>
                            {
                                (subscriptionInfo.subscriptionPayMethod === "SPEI" && subscriptionInfo.status === "PENDING_SPEI") ? <React.Fragment>
                                    Fecha limite de pago: <DateFormat>{moment(subscriptionInfo.dueDate)}</DateFormat>                            
                                    <br/>
                                    Tiene 15 dias para realizar su pago. Puede empezar a usar su almacenamiento alterno, pero debe pagar antes de la fecha limite para evitar perder acceso a el. 
                                    <br/>
                                </React.Fragment> : null
                            }
                            {
                                subscriptionInfo.status === "PAST_DUE" ? <React.Fragment>
                                    Su suscripcion tiene un pago pendiente, por favor complete el pago para reactivar su suscripcion. Toma en cuenta que debido a la falta de pago, su fecha de facturacion sera diferente una vez que su suscripcionse reactive.
                                    <br/>
                                </React.Fragment> : null
                            }
                            {
                                subscriptionInfo.status === "PENDING_SPEI" || subscriptionInfo.status === "ACTIVE" || subscriptionInfo.status === "ACTIVE_NO_RENEWAL" ? <React.Fragment>
                                <br/>
                                    Organizaciones
                                    <SectionList collection={organizations}
                                            noData={<p>{t('NONE')}</p>}>
                                    {
                                        organizations && organizations.map(org => (
                                            <div key={org.organization.id}>
                                                <p style={{fontWeight: 'bold'}}>{org.organization.organizationName}</p>
                                                <Button disabled={!subscriptionInfo.active} color="primary" onClick={() => configureAlternateStorage(org.organization.id)}>Configurar espacio adicional de '{org.organization.organizationName}'</Button>
                                                <br/>
                                                {
                                                    org.subResponse.error ? <React.Fragment>{org.subResponse.error}</React.Fragment> : null
                                                }
                                                {
                                                    org.subResponse.tenantId ? <React.Fragment>Configuracion encontrada: {org.subResponse.structureStorage}</React.Fragment> : null
                                                }
                                            </div>
                                        ))
                                    }
                                </SectionList>
                                </React.Fragment> : null
                            }
                            {
                                subscriptionInfo.status === "ACTIVE" ? <React.Fragment>
                                <Button color="primary" onClick={toggleCancelConfModal}>CANCEL SUBSCRIPTION</Button>
                                <br/>            
                                <ConfirmationModal isOpen={showCancelConfModal}
                                    onCancel={cancelCancelConfModal}
                                    onOk={confirmCancelConfModal}
                                    title="Cancelar suscripcion">
                                        <span>Esta por cancelar sus suscripcion</span>
                                        <br/>
                                        <p>¿Deseas continuar?</p>
                                </ConfirmationModal>
                                </React.Fragment> : null
                            }
                            {
                                subscriptionInfo.status === "ACTIVE_NO_RENEWAL" ? <React.Fragment>
                                    <br/>
                                    Su suscripcion esta activa, mas no se renovara el proximo mes. Debera esperar al fin de su suscripcion para poder cambiar su metodo de pago.
                                    <br/>
                                    <Button color="primary" onClick={toggleReactivateConfModal}>REACTIVAR SUSCRIPCION</Button>
                                    <br/>                                
                                    <ConfirmationModal isOpen={showReactivateConfModal}
                                        onCancel={cancelReactivateConfModal}
                                        onOk={confirmReactivateConfModal}
                                        title="Reactivar suscripcion">
                                            <span>Esta por reactivar sus suscripcion</span>
                                            <br/>
                                            <p>¿Deseas continuar?</p>
                                    </ConfirmationModal>
                                </React.Fragment> : null
                            }
                        </React.Fragment> : 
                        <React.Fragment>
                            Para suscribirse por primera vez, de clic en el boton de abajo.
                            <div>
                                <div className="col">
                                    <div className="row justify-content-start">
                                        <Button color="primary" onClick={openSubscriptionModal}>Suscribir</Button>
                                        <SubscribeModal 
                                            isOpen={subscriptionModal.open} 
                                            onCancel={onCancel}
                                            pendingPayments={pendingLockingDocumentsPayments} 
                                            askFacturacion={false}
                                            existingSubscription={subscriptionInfo}
                                            tenant={tenant}
                                            onComplete={onComplete}
                                        />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    }

                </React.Fragment> : <React.Fragment>Cargando su informacion de suscripcion</React.Fragment>
            }

        </div>
    )
}

export default AlternateStorageSubscription;