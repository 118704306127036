import React, {useState, useEffect} from 'react'
import {httpGet, httpPost} from '../shared/Config';
import {FormGroup, Input, Button} from 'reactstrap';
import InvoiceDataModal from '../invoiceData/InvoiceDataModal'

import { useTranslation } from 'react-i18next';
import InputSelect from "./InputSelect";

function SelectGlobalInvoiceData({disabled}) {
    const {t} = useTranslation();
    const [invoiceDataList, setInvoiceDataList] = useState([]);
    const [invoiceModal, setInvoiceModal] = useState({
        open: false,
        initialState: null
    });
    const [valueSelected, setValueSelected] = useState();
    const [invoiceInfo, setInvoiceInfo] = useState({
        id: '',
        rfc: ''
    })

    const [activeInvoiceInfo, setActiveInvoiceInfo] = useState({
        id: ''
    })
    const [noInvoice, setNoInvoice] = useState(true);
    const [working, setWorking] = useState(false);
    const [initialCheck, setInitialCheck] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchInvoiceDataList(true);
    }, [])

    async function fetchInvoiceDataList(globalFetch) {
        setNoInvoice(false);
        setWorking(true);
        try {
            const response = await httpGet(`/invoiceData/select`);
            if(response.data) {
                setInvoiceDataList(response.data);

                if(response.data.length == 0){
                    setNoInvoice(true);
                }

                if(globalFetch){
                    const currentValue = await httpGet(`/invoiceData/getGlobalInvoice`);
                    if(currentValue.data?.id != null) {
                        setInitialCheck(true);
                        setInvoiceInfo(currentValue.data);
                        setActiveInvoiceInfo(currentValue.data);
                        setNoInvoice(false);
                    } else {
                        if(response.data.length > 0){
                            await httpPost(`/invoiceData/updateInvoiceData`, {
                                invoiceDataId: response.data[0].id
                            });
                            const responseDef = await httpGet(`/invoiceData/${response.data[0].id}`);
                            setInvoiceInfo({...responseDef.data, working: false});
                            setValueSelected(response.data[0].id);
                        }
                    }
                }
            }
        } catch(e) {
            console.error(e);
            setError('COULND_FETCH_INVOICE_DATA');
            setInvoiceDataList([]);
            setWorking(false);
        }
        setWorking(false);
    }

    function handleSelectChange(event) {
        setWorking(true);
        setValueSelected(event.target.value);
    }

    useEffect(() => {
        if(valueSelected) {
            async function updateInvoice(){
            await httpPost(`/invoiceData/updateInvoiceData`, {
                    invoiceDataId: valueSelected
                });
                const responseDef = await httpGet(`/invoiceData/${valueSelected}`);
                setInvoiceInfo({...responseDef.data, working: false});
                setActiveInvoiceInfo(responseDef.data);
                setWorking(false);
            };

            updateInvoice();
        }
    }, [valueSelected])

    function handleInvoiceDataSaved(dataSaved) {
        setWorking(true);
        setValueSelected(dataSaved.id);
        setInvoiceModal({initialState: null, open: false});
        window.location.reload();
    }

    function handleEdit(iData) {
        return function() {
            if(!iData.regimenFiscal) {
                iData.regimenFiscal = {id: 0};
            }
            setInvoiceModal({initialState: iData, open: true})
        }
    }

    function handleAdd() {
        setInvoiceModal({initialState: null, open: true})
    }

    return (
        <React.Fragment>
            <b>{t('BILLING_CONTACT')}</b>
            <br/>
            <br/>
                {
                    invoiceDataList.length > 0 ? <React.Fragment>
                        <b>{t('ACTIVE_BILLING_CONTACT')}</b>
                        <br/>
                        <br/>
                        <p>
                            {t('COMPANY_NAME')}: {activeInvoiceInfo.name ? <React.Fragment>{activeInvoiceInfo.name }</React.Fragment> : <React.Fragment>{t('UNASSIGNED')}</React.Fragment> } <br/>
                            {t('RFC')}: {activeInvoiceInfo.rfc ? <React.Fragment>{activeInvoiceInfo.rfc }</React.Fragment> : <React.Fragment>{t('UNASSIGNED')}</React.Fragment> } <br/>
                            {t('NAME')}: {activeInvoiceInfo.contactName ? <React.Fragment>{activeInvoiceInfo.contactName }</React.Fragment> : <React.Fragment>{t('UNASSIGNED')}</React.Fragment> } <br/>
                            {t('PHONE')}:  {activeInvoiceInfo.contactPhone ? <React.Fragment>{activeInvoiceInfo.contactPhone }</React.Fragment> : <React.Fragment>{t('UNASSIGNED')}</React.Fragment> } <br/>
                            {t('EMAIL')}: {activeInvoiceInfo.email ? <React.Fragment>{activeInvoiceInfo.email }</React.Fragment> : <React.Fragment>{t('UNASSIGNED')}</React.Fragment> } <br/>
                            {/* {t('STREET')}: {activeInvoiceInfo.street ? <React.Fragment>{activeInvoiceInfo.street }</React.Fragment> : <React.Fragment>{t('UNASSIGNED')}</React.Fragment> } <br/> */}
                            {/*{t('EXTERIOR_NUMBER')}: {activeInvoiceInfo.exteriorNumber ? <React.Fragment>{activeInvoiceInfo.exteriorNumber }</React.Fragment> : <React.Fragment>{t('UNASSIGNED')}</React.Fragment> } <br/> */}
                            {t('POSTAL_CODE')}: {activeInvoiceInfo.postalCode ? <React.Fragment>{activeInvoiceInfo.postalCode }</React.Fragment> : <React.Fragment>{t('UNASSIGNED')}</React.Fragment> } <br/>
                            {/*{t('CITY')}: {activeInvoiceInfo.city ? <React.Fragment>{activeInvoiceInfo.city }</React.Fragment> : <React.Fragment>{t('UNASSIGNED')}</React.Fragment> } <br/> */}
                            {/*{t('STATE')}: {activeInvoiceInfo.state ? <React.Fragment>{activeInvoiceInfo.state }</React.Fragment> : <React.Fragment>{t('UNASSIGNED')}</React.Fragment> } <br/> */}
                            {/*{t('COUNTRY')}: {activeInvoiceInfo.country?.countryName ? <React.Fragment>{activeInvoiceInfo.country.countryName }</React.Fragment> : <React.Fragment>{t('UNASSIGNED')}</React.Fragment> } <br/> */}
                            {t('CFDI_USE')}: { activeInvoiceInfo.cfdiId ? <React.Fragment>{activeInvoiceInfo.cfdiId.desc}</React.Fragment> : <React.Fragment>{t('UNASSIGNED')}</React.Fragment> } <br/>
                            {t('REGIMEN')}: { activeInvoiceInfo.regimenFiscal ? <React.Fragment> {activeInvoiceInfo.regimenFiscal.data}</React.Fragment> : <React.Fragment>{t('UNASSIGNED')}</React.Fragment>}
                        </p>
                        <div>
                            <div className="row justify-content-start">
                                <button className={'btn btn-secondary'} disabled={working} style={{marginTop: '10px', marginRight:'10px'}} color="primary" onClick={handleEdit(activeInvoiceInfo)}>{t('EDIT_CONTACT')}</button>
                            </div>
                            <br/>
                            <span>{t('CONTACTS')}: {invoiceDataList.length}/12
                            </span>
                            <InputSelect label={t('SELECT_BILLING_CONTACT')}
                                        value={valueSelected}
                                        onChange={handleSelectChange}
                                        disabled={working}
                                        required>
                                <option selected disabled></option>
                                {
                                    invoiceDataList.map(i => (
                                        <option key={i.id} value={i.id}>{i.email} - {i.rfc} </option>
                                    ))
                                }
                            </InputSelect>
                        </div>
                        <br/>
                    </React.Fragment> : <React.Fragment>
                        <br/>
                        <span style={{color: 'red', fontWeight:'bold'}}>{t('NO_BILLING_CONTACT')}</span>
                    </React.Fragment>
                }
            <hr/>
            <br/>
            <div className="row justify-content-center">
                <button className={'btn btn-secondary'} disabled={working || invoiceDataList.length >= 12} color="primary" onClick={handleAdd}>{t('ADD_NEW_BILLING_CONTACT')}</button>
            </div>
            <br/>
            <InvoiceDataModal
                isOpen={invoiceModal.open}
                initialState={invoiceModal.initialState}
                onCancel={() => setInvoiceModal({initialState: null, open: false})}
                onSaved={handleInvoiceDataSaved}/>
        </React.Fragment>
    )

}

export default SelectGlobalInvoiceData
