import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {backend} from '../shared/Config';
import {Button} from 'reactstrap'
import SupportDataModal from './SupportDataModal'
import SectionList from '../shared/SectionList'


function SupportDataList() {

    const [supportDataList, setSupportDataList] = useState([])
    const [supportModal, setSupportModal] = useState({
        open: false,
        initialState: null
    });

    useEffect(() => {
        async function fetchAllSupportData() {
            try {
                const response = await axios.get(backend('/supportData'));
                setSupportDataList(response.data);
            } catch(e) {
                console.error(e);
                setSupportDataList([]);
            }
        }

        fetchAllSupportData();
    }, [])

    function handleAdd() {
        setSupportModal({initialState: null, open: true})
    }

    function handleSupportDataSaved(iData) {
        if(supportModal.initialState) {
            setSupportDataList(supportDataList.map(i => i.id === iData.id ? iData : i));
        } else {
            setSupportDataList([iData, ...supportDataList])
        }
        setSupportModal({initialState: null, open: false})        
    }

    function handleEdit(iData) {
        return function() {
            setSupportModal({initialState: iData, open: true})            
        }
    }

    return (
        <div className="row">            
            <div className="col">
                <h3>Datos de soporte</h3>
                <div className="row justify-content-end">
                    <Button color="primary" onClick={handleAdd}>Agregar</Button>
                </div>
                <SectionList collection={supportDataList} noData={<p>Sin información</p>}>
                    {
                        supportDataList.map(iData => (
                            <div key={iData.id}>
                                <p style={{fontWeight:'bold'}}>{iData.supportName}</p>
                                <p>
                                    {iData.supportEmail} <br/>
                                    {iData.supportPhone} <br/>
                                </p>
                                <div className="row justify-content-end">
                                    <Button color="primary" onClick={handleEdit(iData)}>Editar</Button>
                                </div>
                            </div>
                        ))
                    }
                </SectionList>
            </div>
            <SupportDataModal
                isOpen={supportModal.open}
                initialState={supportModal.initialState}
                onCancel={() => setSupportModal({initialState: null, open: false})}
                onSaved={handleSupportDataSaved}/>
        </div>
    )
}

export default SupportDataList;
